<template>
  <ValidationObserver v-slot="{ passes }" slim>
    <form :class="classNames" @submit.prevent="passes(handleSubmit)">
      <FormInput
        v-model="email.value"
        class="TheNewsletterForm__Email"
        theme="newsletter"
        :name="fields.email.name"
        :label="fields.email.label"
        :placeholder="fields.email.placeholder"
        :rules="email.rules"
      />
      <button class="TheNewsletterForm__Submit" type="submit">
        <BaseIcon icon="chevron-right" />
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import FormInput from '@/components/FormInput'
import { ValidationObserver } from 'vee-validate'

export default {
  name: 'TheNewsletterForm',
  components: {
    BaseIcon,
    FormInput,
    ValidationObserver,
  },
  props: {
    fields: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['email'].every((key) => key in value)
      },
    },
    notifications: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: 'Titre par défaut',
          success: 'Message de succès par défaut',
          fail: "Message d'erreur par défaut",
        }
      },
      validator: (value) => {
        return ['success', 'fail', 'title'].every((key) => key in value)
      },
    },
  },
  data() {
    return {
      email: {
        value: '',
        rules: 'required|email',
      },
    }
  },
  computed: {
    classNames() {
      return ['TheNewsletterForm']
    },
  },
  methods: {
    ...mapActions('api', ['postNewsletter']),
    handleSubmit() {
      this.postNewsletter({
        email: this.email.value,
      })
        .then(() => {
          this.notify(
            this.notifications.title,
            this.notifications.success,
            'success',
          )
          this.$gtm.pushNewsletter()
        })
        .catch((e) => {
          this.notify(
            this.notifications.title,
            `${this.notifications.fail} - ${e.message}`,
            'error',
          )
        })
      this.reset()
    },
    notify(title, text, type) {
      this.$notify({
        type,
        title,
        text,
      })
    },
    reset() {
      this.email.value = ''
    },
  },
}
</script>

<style lang="scss">
.TheNewsletterForm {
  position: relative;

  display: flex;
  justify-content: flex-start;
  align-items: flex-start;

  // Line

  &::after {
    position: absolute;
    top: 60px;
    left: $gutter/2;
    z-index: 1;

    display: block;
    width: calc(100% - #{$gutter});
    height: 2px;

    background-color: $color-tertiary;

    content: '';
  }

  // Email

  &__Email {
    flex: 1 1 auto;
    input {
      background-color: transparent;
    }
    label > span {
      @include hide-visually;
    }
  }

  // Submit

  &__Submit {
    flex: 0 1 auto;
    margin-top: 19px;
    margin-left: $spacer;
    padding: 0;
    cursor: pointer;
    appearance: none;
    background: none;
    color: $color-secondary;
    font-size: 2.3rem;
  }
}
</style>
