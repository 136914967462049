<template>
  <section id="about" class="TheAbout">
    <BaseForeground theme="about" />
    <div class="TheAbout__Inner">
      <GridRow class="TheAbout__Row">
        <TheAboutImages :images="content.images" />
        <TheAboutContent
          :suptitle="content.suptitle"
          :title="content.title"
          :text="content.text"
          :cta="content.cta"
        />
      </GridRow>
    </div>
  </section>
</template>

<script>
import BaseForeground from '@/components/BaseForeground'
import GridRow from '@/components/GridRow'
import TheAboutContent from './components/TheAboutContent'
import TheAboutImages from './components/TheAboutImages'

export default {
  name: 'TheAbout',
  components: {
    BaseForeground,
    GridRow,
    TheAboutContent,
    TheAboutImages,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'text', 'cta', 'images'].every((key) => key in value)
      },
    },
  },
}
</script>

<style lang="scss">
.TheAbout {
  @include segment;
  @include section;
  @include sm {
    padding-top: 2rem;
  }
  position: relative;
  z-index: $index-about;

  // Background

  &__Background {
    background-image: url('../../assets/images/foreground_about.svg');
  }

  // Inner

  &__Inner {
    @include inner;
    position: relative;
    z-index: 1;
  }
}
</style>
