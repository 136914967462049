<template>
  <div v-rellax="rellaxOptions" class="TheShopsList">
    <TheShopsListItem
      v-for="(item, index) in items"
      :key="`item-${index}`"
      :active="current === index"
      :index="index"
      :coordinates="item.coordinates"
      :name="item.name"
      :address="item.address"
      :description="item.description"
      :phone="item.phone"
      :email="item.email"
      :contact="item.contact"
      @click="handleClick"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheShopsListItem from './TheShopListItem'

export default {
  name: 'TheShopsList',
  components: {
    TheShopsListItem,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: 0,
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    rellaxOptions() {
      return this.isDesktop ? { speed: 2, center: true } : false
    },
  },
  methods: {
    handleClick(index) {
      this.current = index
      this.$emit('change', index)
      this.$gtm.pushAddress({
        name: this.items[index].name || null,
      })
    },
  },
}
</script>

<style lang="scss">
.TheShopsList {
  @include lg-up {
    padding: $spacer;
  }
  width: 100%;
  height: 100%;
  background-color: $color-tertiary;
}
</style>
