export default [
  {
    featureType: 'administrative.locality',
    elementType: 'all',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: 7,
      },
      {
        lightness: 19,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'all',
    stylers: [
      {
        hue: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'landscape',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#f6f1e8',
      },
    ],
  },
  {
    featureType: 'poi',
    elementType: 'all',
    stylers: [
      {
        hue: '#ffffff',
      },
      {
        saturation: -100,
      },
      {
        lightness: 100,
      },
      {
        visibility: 'off',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: -52,
      },
      {
        lightness: -10,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road',
    elementType: 'labels',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: -93,
      },
      {
        lightness: 31,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'road.arterial',
    elementType: 'labels',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: -93,
      },
      {
        lightness: -2,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'road.local',
    elementType: 'geometry',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: -52,
      },
      {
        lightness: -10,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'transit',
    elementType: 'all',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: 10,
      },
      {
        lightness: 69,
      },
      {
        visibility: 'on',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'all',
    stylers: [
      {
        hue: '#c79c60',
      },
      {
        saturation: -78,
      },
      {
        lightness: 67,
      },
      {
        visibility: 'simplified',
      },
    ],
  },
  {
    featureType: 'water',
    elementType: 'geometry.fill',
    stylers: [
      {
        color: '#fbfbfb',
      },
    ],
  },
]
