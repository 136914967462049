import Vue from 'vue'
import VueMeta from 'vue-meta'
import VueScrollTo from 'vue-scrollto'
import VueTransitionReveal from './vue-transition-reveal'
import Notifications from 'vue-notification'
import VScrollLock from 'v-scroll-lock'
import Vuebar from 'vuebar'

import VueGtm from './vue-gtm'

import * as VueGoogleMaps from 'vue2-google-maps'

Vue.use(VueGtm)

Vue.use(VueMeta)
Vue.use(Vuebar)
Vue.use(VScrollLock)
Vue.use(VueTransitionReveal)
Vue.use(Notifications)
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyC_3-JxlR71jRiC_ICKXPnVZRPfyucQYUc',
    libraries: '',
    // v: '3.26',
  },
  autobindAllEvents: false,
  installComponents: true,
})

Vue.use(VueScrollTo, {
  container: 'body',
  duration: 800,
  easing: 'ease',
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: true,
  y: true,
})
