export default {
  pushPageLoad: (params) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'pageview',
        pageName: params.name || '',
        language: params.language || 'en',
        path: params.path || '/',
        template_responsive: params.responsive || '',
      })
    }
  },
  pushAddress: (params) => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'adresse',
        action: params.name || '',
      })
    }
  },
  pushNewsletter: () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'newsletter',
      })
    }
  },
  pushContact: () => {
    if (window.dataLayer) {
      window.dataLayer.push({
        event: 'contact',
      })
    }
  },
}
