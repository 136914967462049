<template>
  <section class="TheCover">
    <div class="TheCover__Wrapper">
      <BaseImage class="TheCover__Image" position="50% 20%" :src="image.src" />
      <div class="TheCover__Inner">
        <div class="TheCover__Content">
          <h1 class="TheCover__Title" v-html="title" />
          <!-- <a href="#" @click.prevent="handleClick">
            <img
              class="TheCover__Icon"
              src="../../assets/images/cover_mouse.svg"
              alt="mouse"
            />
          </a> -->
        </div>
      </div>
      <!-- <TheGiftCard class="TheCover__Gift" :content="content.gift" /> -->
    </div>
  </section>
</template>

<script>
import BaseImage from '@/components/BaseImage'
// import TheGiftCard from '@/components/TheGiftCard'
export default {
  name: 'TheCover',
  components: {
    BaseImage,
    // TheGiftCard,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'image', /*'gift'*/].every((key) => key in value)
      },
    },
  },
  computed: {
    title() {
      return this.content.title
    },
    image() {
      return this.content.image
    },
    backgroundImage() {
      return `
        background-image: url(${this.image.src});
      `
    },
  },
  methods: {
    handleClick() {
      this.$scrollTo('#about')
    },
  },
}
</script>

<style lang="scss">
.TheCover {
  @include section;
  @include center-flex;
  @include responsive-property(height, 660px, 764px);
  @include responsive-property(
    padding-top,
    $container-gutter-mobile,
    $container-gutter-desktop
  );

  position: relative;

  z-index: $index-cover;

  // Wrapper

  &__Wrapper {
    @include segment;
    @include responsive-property(padding-left, $gutter, $gutter * 2);
    @include responsive-property(padding-right, $gutter, $gutter * 2);
    @include md-down {
      padding-bottom: 13rem;
      background-color: red;
    }
    position: relative;
    width: 100%;
  }

  // Image

  &__Image {
    @include set-overlay(rgba($color-tertiary, 1));
    @include fit;
    z-index: 0;

    .BaseImage__Background {
      opacity: 0.25;
    }
  }

  // Inner

  &__Inner {
    @include inner;
    position: relative;
  }

  // Content

  &__Content {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  // Title

  &__Title {
    @include responsive-property(font-size, 40px, 62px);
    flex: 0 1 auto;
    color: white;
  }

  // Icon

  &__Icon {
    position: absolute;
    left: 0;
    bottom: 0;

    width: 26px;
    height: 26px;
  }
}
</style>
