<template>
  <span :class="classNames">
    <i :class="iconClasses"></i>
  </span>
</template>

<script>
export default {
  name: 'BaseIcon',
  props: {
    icon: {
      type: [String, null],
      required: true,
    },
  },
  computed: {
    classNames() {
      return ['BaseIcon']
    },
    iconClasses() {
      return `icon-${this.icon}`
    },
  },
}
</script>

<style lang="scss">
.BaseIcon {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  i {
    flex: 0 1 auto;
  }

  [class^='icon-'],
  [class*=' icon-'] {
    /* use !important to prevent issues with browser extensions that change fonts */
    font-family: $font-icon, serif !important;
    speak: none;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    display: inline-block;

    /* Better Font Rendering =========== */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  .icon-arrow:before {
    content: '\e900';
  }
  .icon-chevron-right:before {
    content: '\e901';
  }
  .icon-chevron-left:before {
    content: '\e902';
  }
  .icon-chevron-down:before {
    content: '\e903';
  }
  .icon-chevron-up:before {
    content: '\e904';
  }
  .icon-map-pin:before {
    content: '\e905';
  }
  .icon-email:before {
    content: '\e906';
  }
  .icon-phone:before {
    content: '\e907';
  }
}
</style>
