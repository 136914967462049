<template>
  <div class="Home">
    <TheHeader :content="content.header" />
    <main class="Home__layout">
      <TheCover :content="content.cover" />
      <TheContact :content="content.contact" />
      <TheGiftCard v-if="content.gift" :content="content.gift" />
      <TheAbout :content="content.about" />
      <TheOffers :content="content.offers" />
      <TheBestsellers :content="content.bestsellers" />
      <TheShops :content="content.shops" />
      <TheNewsletter :content="content.newsletter" />
      <TheModal />
    </main>
    <TheFooter :content="content.footer" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheHeader from '@/components/TheHeader'
import TheCover from '@/components/TheCover'
import TheGiftCard from '@/components/TheGiftCard'
import TheAbout from '@/components/TheAbout'
import TheOffers from '@/components/TheOffers'
import TheBestsellers from '@/components/TheBestsellers'
import TheShops from '@/components/TheShops'
import TheContact from '@/components/TheContact'
import TheNewsletter from '@/components/TheNewsletter'
import TheModal from '@/components/TheModal'
import TheFooter from '@/components/TheFooter'

import { Metas, Views } from '@/mixins'

export default {
  name: 'Home',
  components: {
    TheHeader,
    TheCover,
    TheGiftCard,
    TheContact,
    TheAbout,
    TheOffers,
    TheBestsellers,
    TheShops,
    TheNewsletter,
    TheModal,
    TheFooter,
  },
  mixins: [Metas, Views],
  computed: {
    ...mapGetters('api', {
      content: 'getContent',
    }),
  },
}
</script>

<style lang="scss">
.Home {
  position: relative;
}
</style>
