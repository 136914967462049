import { cloneDeep } from 'lodash'

export default class StoreFetch {
  static state() {
    return {
      data: null,
      status: {
        pending: false,
        success: false,
        fail: false,
        error: null,
      },
    }
  }

  static updateState(state, data = null) {
    if (data === null) {
      return this._mutationPending({ ...state })
    }
    return data instanceof Error
      ? this._mutationFail({ ...state }, data)
      : this._mutationSuccess({ ...state }, data)
  }

  static _mutationPending(state) {
    state.data = null
    state.status.pending = true
    state.status.success = false
    state.status.fail = false
    state.status.error = null
    return state
  }

  static _mutationSuccess(state, data) {
    state.data = cloneDeep(data)
    state.status.pending = false
    state.status.success = true
    state.status.fail = false
    state.status.error = null
    return state
  }

  static _mutationFail(state, data) {
    state.data = null
    state.status.pending = false
    state.status.success = false
    state.status.fail = true
    state.status.error = data
    return state
  }
}
