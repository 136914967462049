<template>
  <component
    :is="tagName"
    :class="classNames"
    :href="url ? url : null"
    :to="route ? route : null"
  >
    <span class="BaseLink__Text">{{ text }}</span>
    <BaseIcon class="BaseLink__Icon" icon="arrow" />
  </component>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  name: 'BaseLink',
  components: {
    BaseIcon,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    url: {
      type: [String],
      default: '',
    },
    route: {
      type: [Object, String],
      default: '',
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    classNames() {
      return ['BaseLink', `BaseLink--${this.theme}`]
    },
    tagName() {
      return this.url ? 'a' : this.route ? 'router-link' : 'button'
    },
  },
}
</script>

<style lang="scss">
.BaseLink {
  @include hover {
    .BaseLink__Icon {
      margin-left: $spacer;
    }
  }

  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;

  // Text

  &__Text {
    flex: 0 1 auto;
    font-size: 1.1rem;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
  }

  // Icon

  &__Icon {
    @include transition(margin-left opacity);

    flex: 0 0 auto;
    margin-left: $spacer/2;
    font-size: 1.2rem;
    will-change: margin-left;
  }

  // Themes

  // Themes | Default

  &--default {
    color: $color-secondary;
  }

  // Themes | Contact

  &--contact {
    color: white;
  }
}
</style>
