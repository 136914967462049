import Vue from 'vue'
import VueRellax from './VueRellax'
import { wait } from '@/helpers/time'

Vue.directive('rellax', {
  inserted: (el, binding, vnode) => {
    wait(50, () => {
      el.controller = new VueRellax(el, binding.value)
    })
  },
  update: (el, binding, vnode) => {
    el.controller.reset(binding.value)
  },
})
