<template>
  <div :class="classNames" v-html="text"></div>
</template>

<script>
export default {
  name: 'BaseText',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    classNames() {
      return ['BaseText']
    },
  },
}
</script>

<style lang="scss">
.BaseText {
  // padding-bottom: $spacer;

  p {
    @include responsive-property(font-size, 17px, 18px);
    font-weight: 300;
    margin-bottom: $spacer * 1;
  }

  b,
  strong {
    font-weight: 600;
  }

  a {
    @include transition;
    color: $color-primary;
    will-change: color;

    &:hover,
    &:focus {
      color: shade($color-primary, 30%);
    }
  }

  h2,
  h3,
  h4,
  h5,
  h6 {
    color: $color-primary;
    font-weight: 600;
  }

  h2,
  h3 {
    margin-bottom: $spacer * 1;
  }

  h2 {
    font-size: 3.2rem;
  }

  h3 {
    font-size: 2.8rem;
  }

  h4,
  h5,
  h6 {
    @include responsive-property(font-size, 17px, 18px);
    margin-bottom: $spacer * 1;
  }
}
</style>
