import Rellax from 'rellax'

export default class VueRellax {
  constructor(el, options) {
    this.element = el
    this.rellax = null
    this.createRellax(options)
  }
  createRellax(options) {
    if (options !== false) {
      this.rellax = new Rellax(this.element, options)
    }
  }
  destroyRellax() {
    if (this.rellax) this.rellax.destroy()
  }
  reset(options) {
    this.destroyRellax()
    this.createRellax(options)
  }
}
