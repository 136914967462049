<template>
  <footer class="TheFooter">
    <div class="TheFooter__Inner">
      <div class="TheFooter__Top">
        <TheFooterLogo :text="content.logo" />
        <TheFooterMenu :items="content.items" />
      </div>
      <div class="TheFooter__Bottom">
        <TheFooterCopyright :text="content.copyright" />
      </div>
    </div>
  </footer>
</template>

<script>
import TheFooterMenu from './components/TheFooterMenu'
import TheFooterLogo from './components/TheFooterLogo'
import TheFooterCopyright from './components/TheFooterCopyright'

export default {
  name: 'TheFooter',
  components: {
    TheFooterLogo,
    TheFooterMenu,
    TheFooterCopyright,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['items', 'logo', 'copyright'].every((key) => key in value)
      },
    },
  },
}
</script>

<style lang="scss">
.TheFooter {
  @include section;
  @include md-down {
    width: calc(100% + (#{$gutter} * 2));
    margin-left: -$gutter;
    margin-right: -$gutter;
    background-color: $color-foreground;
  }

  position: relative;
  z-index: $index-footer;
  padding-bottom: 0 !important;

  // Inner

  &__Inner {
    @include inner;
    flex-direction: column;
  }

  // Top

  &__Top {
    @include md-down {
      justify-content: center;
      flex-direction: column;
      padding: $spacer;
    }
    @include lg-up {
      justify-content: space-between;
    }

    display: flex;
    align-items: center;
    padding-top: $spacer/2;
    padding-bottom: $spacer/2;
  }

  // Bottom

  &__Bottom {
    @include md-down {
      border-color: shade($color-foreground, 10%);
      padding: $spacer * 0.75 0;
    }
    @include lg-up {
      border-color: shade($color-foreground, 5%);
      padding: $spacer 0;
    }

    border-top-width: 1px;
    border-top-style: solid;
  }
}
</style>
