import { mapGetters } from 'vuex'

export default {
  metaInfo() {
    return {
      title: this.metaInfo.title || 'La cave de Jonas',
      meta: [
        // Search Engine
        { name: 'title', content: this.metaInfo.meta.title || '' },
        { name: 'description', content: this.metaInfo.meta.description || '' },
        { name: 'image', content: this.metaInfo.meta.image || '' },
        // Schema.org for Google
        { itemprop: 'name', content: this.metaInfo.meta.title || '' },
        {
          itemprop: 'description',
          content: this.metaInfo.meta.description || '',
        },
        { itemprop: 'image', content: this.metaInfo.meta.image || '' },
        // Twitter
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:title', content: this.metaInfo.meta.title || '' },
        {
          name: 'twitter:description',
          content: this.metaInfo.meta.description || '',
        },
        // Open Graph general (Facebook, Pinterest & Google+)
        { name: 'og:title', content: this.metaInfo.meta.title || '' },
        {
          name: 'og:description',
          content: this.metaInfo.meta.description || '',
        },
        { name: 'og:image', content: this.metaInfo.meta.image || '' },
        { name: 'og:url', content: this.metaInfo.meta.url || '' },
        { name: 'og:site_name', content: 'Arthuis' },
        { name: 'og:type', content: 'website' },
      ],
    }
  },
  computed: {
    ...mapGetters('api', {
      metaInfo: 'getContentMetaInfo',
    }),
  },
}
