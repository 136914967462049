<template>
  <div v-rellax="rellaxOptions" class="TheShopsMap">
    <GmapMap
      ref="map"
      :zoom="13"
      :center="center"
      :options="options"
      map-type-id="terrain"
    >
      <GmapMarker
        v-for="(m, index) in markers"
        :key="index"
        :position="m.position"
        :clickable="false"
        :draggable="false"
        :icon="{ url: markerIcon }"
        @click="handleMarkerClick(index)"
      />
    </GmapMap>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import MapStyles from './MapStyles'
import MapPin from '@/assets/images/map_pin.svg'

export default {
  name: 'TheShopsMap',
  props: {
    markers: {
      type: Array,
      required: true,
    },
    currentIndex: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      markerIcon: MapPin,
      options: {
        styles: MapStyles,
      },
      center: {
        lat: 0,
        lng: 0,
      },
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    rellaxOptions() {
      return this.isDesktop ? { speed: -1, center: true } : false
    },
  },
  watch: {
    currentIndex() {
      const center = this.markers[this.currentIndex].position
      this.$refs.map.$mapPromise.then((map) => {
        map.panTo(center)
      })
    },
  },
  created() {
    this.center = this.markers[0].position
  },
  mounted() {
    this.$refs.map.$mapPromise.then((map) => {
      map.panTo(this.center)
    })
  },
  methods: {
    handleMarkerClick(index) {
      // this.$refs.map.map.panTo(this.markers[index].position)
      this.$emit('change', index)
    },
  },
}
</script>

<style lang="scss">
.TheShopsMap {
  width: 100%;
  height: 100%;

  .vue-map-container {
    width: 100%;
    height: 100%;
  }
}
</style>
