import axios from 'axios'
import content from './_modules/content'
import contact from './_modules/contact'
import newsletter from './_modules/newsletter'

const modules = {
  content,
  contact,
  newsletter,
}

const Api = {
  fetch: (name) => modules[name],
}

const baseDomain = `${process.env.BASE_URL}${process.env.VUE_APP_API}`
const baseURL = `${baseDomain}`

const Provider = axios.create({
  baseURL,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    // 'Authorization': `Token`
  },
})

export { Provider, Api }
