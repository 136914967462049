<template>
  <div class="TheOffersDetailsItem">
    <div v-bar class="TheOffersDetailsItem__ScrollContainer">
      <div>
        <div class="TheOffersDetailsItem__Content">
          <BaseHeading
            class="TheOffersDetailsItem__Heading"
            :suptitle="type"
            :title="name"
          />
          <BaseText class="TheOffersDetailsItem__Text" :text="details" />
          <div class="TheOffersDetailsItem__Prices">
            <BasePrice
              v-if="priceOriginalAmount !== priceDiscountAmount"
              :amount="priceOriginalAmount"
              :currency="priceOriginalCurrency"
              strike
              small
            />
            <BasePrice
              :amount="priceDiscountAmount"
              :currency="priceDiscountCurrency"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseHeading from '@/components/BaseHeading'
import BaseText from '@/components/BaseText'
import BasePrice from '@/components/BasePrice'

export default {
  name: 'TheOffersDetailsItem',
  components: {
    BaseText,
    BaseHeading,
    BasePrice,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    details: {
      type: String,
      required: true,
    },
    prices: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    priceOriginalAmount() {
      return this.prices.original.amount
    },
    priceOriginalCurrency() {
      return this.prices.original.currency
    },
    priceDiscountAmount() {
      return this.prices.discount.amount || this.prices.original.amount
    },
    priceDiscountCurrency() {
      return this.prices.discount.currency
    },
  },
}
</script>

<style lang="scss">
.TheOffersDetailsItem {
  $padding-desktop: $spacer * 2;
  @include lg-up {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
    height: 100%;
  }

  box-sizing: border-box;
  position: relative;
  z-index: 10;

  // Scrollable

  &__ScrollContainer {
    // @include lg-up {
    //   @include scroll-gradients($color-foreground, $spacer);
    // }

    height: 100%;
  }

  // Content

  &__Content {
    @include sm {
      padding-top: $spacer;
      padding-bottom: $spacer * 1.5;
    }
    @include md {
      padding-top: $spacer * 1.5;
      padding-bottom: $spacer * 1.5;
    }
    @include lg-up {
      padding-right: 30px;
      padding-top: $spacer;
      padding-bottom: $spacer;
    }

    box-sizing: border-box;
  }

  // Heading

  &__Heading {
    .BaseHeading__Suptitle {
      font-size: 1.1rem !important;
      padding-bottom: 5px;
      text-transform: uppercase;
      font-weight: 600;
    }

    .BaseHeading__Title {
      font-size: 3.2rem !important;
    }
  }

  // Text

  &__Text {
    color: $color-secondary;

    h3 {
      @include responsive-property(font-size, 17px, 18px);
      margin: 0;
      font-family: $font-family;
      font-weight: 600;
      color: $color-secondary;
    }
  }

  // Prices

  &__Prices {
    line-height: 1.2;
  }
}
</style>
