import { Provider } from '@/api'

const resource =
  process.env.NODE_ENV === 'development' ? '/contact.json' : '/contact'

export default {
  post(params) {
    return Provider.post(`${resource}`, params)
  },
}
