<template>
  <section id="gift-card" class="gift-card">
    <div class="gift-card__inner">
      <div class="gift-card__text">
        <span v-html="content.text" />
      </div>
      <img class="gift-card__image" :src="content.image" alt="Carte cadeau" />
    </div>
  </section>
</template>

<script>
export default {
  name: 'TheGiftCard',
  props: {
    content: {
      type: Object,
      default: () => ({
        text: '',
        image: '',
      }),
    },
  },
}
</script>

<style lang="scss">
.gift-card {
  position: relative;
  z-index: 7;
  top: 0rem;
  width: 100%;

  &__inner {
    @include md-down {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 3.5rem 4rem;
      background-size: contain;
      min-height: 12rem;
    }
    @include lg-up {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      max-width: 38rem;
      min-height: 12rem;
      padding: 3.5rem 4rem;
      background-size: cover;

      position: absolute;
      top: -3rem;
      left: 0;

      transform: translateY(-100%);
    }

    background-color: #171741;
    background-image: url('../../assets/images/gift_card_bg.png');
    background-repeat: no-repeat;
    background-position: top left;
  }

  &__image {
    @include md-down {
      position: absolute;
      padding: 2.5rem 0rem 1rem;
    }
    @include sm {
      right: 0rem;
      bottom: -1rem;
      transform: translateY(50%);
      width: 18rem;
    }
    @include md {
      right: 0rem;
      top: 55%;
      transform: translateY(-50%);
      width: 23rem;
    }
    @include lg-up {
      position: absolute;
      right: 0;
      top: 60%;
      width: 23rem;
      transform: translateX(50%) translateY(-50%);
    }

    box-sizing: content-box;
  }

  &__text {
    @include sm {
      width: 100%;
    }
    @include md {
      width: calc(100% - 23rem);
    }
    @include lg-up {
      padding-right: 8rem;
    }

    color: white;
    font-family: $font-heading;
    font-size: 2rem;
    font-weight: 500;
  }
}
</style>
