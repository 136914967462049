<template>
  <button :class="classNames">
    <div>
      <span></span>
      <span></span>
    </div>
  </button>
</template>

<script>
export default {
  name: 'BaseClose',
  props: {
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    classNames() {
      return ['BaseClose', `BaseClose--${this.theme}`]
    },
  },
}
</script>

<style lang="scss">
.BaseClose {
  @include transition(transform);

  cursor: pointer;
  appearance: none;
  background: none;
  border: none;
  outline: none;
  display: block;
  will-change: transform;

  > div {
    @include size(45px);
    position: relative;
    transform: rotate(45deg);
    border: 2px solid white;
    border-radius: 50%;
  }

  span {
    @include center-abs;
    display: block;
    width: 60%;
    height: 2px;
    background-color: white;
    transform-origin: center;

    &:first-of-type {
    }

    &:last-of-type {
      transform: translate(-50%, -50%) rotate(90deg);
    }
  }

  // Themes
  // Themes Light

  &--light {
    > div {
      border: 0;
    }
  }
}
</style>
