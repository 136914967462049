import { Provider } from '@/api'

const resource =
  process.env.NODE_ENV === 'development' ? '/content.json' : '/content'

export default {
  get() {
    return Provider.get(`${resource}`)
  },
}
