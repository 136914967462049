<template>
  <div class="TheHeaderHamburger">
    <BaseHamburger :active="active" @click="handleClick" />
  </div>
</template>

<script>
import BaseHamburger from '@/components/BaseHamburger'

export default {
  name: 'TheHeaderHamburger',
  components: {
    BaseHamburger,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>
