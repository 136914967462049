<template>
  <div class="Swiper swiper-container">
    <div class="swiper-wrapper">
      <slot name="default"></slot>
    </div>
    <slot name="pagination"></slot>
    <slot name="buttonPrev"></slot>
    <slot name="buttonNext"></slot>
    <slot name="scrollbar"></slot>
  </div>
</template>

<script>
import 'swiper/css/swiper.css'
import { mapGetters } from 'vuex'
import Swiper from 'swiper'

const DEFAULT_EVENTS = [
  'beforeDestroy',
  'slideChange',
  'slideChangeTransitionStart',
  'slideChangeTransitionEnd',
  'slideNextTransitionStart',
  'slideNextTransitionEnd',
  'slidePrevTransitionStart',
  'slidePrevTransitionEnd',
  'transitionStart',
  'transitionEnd',
  'touchStart',
  'touchMove',
  'touchMoveOpposite',
  'sliderMove',
  'touchEnd',
  'click',
  'tap',
  'doubleTap',
  'imagesReady',
  'progress',
  'reachBeginning',
  'reachEnd',
  'fromEdge',
  'setTranslate',
  'setTransition',
  'resize',
  'observerUpdate',
  'beforeLoopFix',
  'loopFix',
]

export default {
  name: 'Swiper',
  props: {
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {},
      defaultOptions: {
        init: false,
        threshold: 30,
        observer: false,
        keyboard: {
          enabled: true,
          onlyInViewport: false,
        },
      },
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isMobile']),
  },
  watch: {
    isMobile() {
      this.reset()
    },
  },
  created() {
    window.addEventListener('resize', () => {
      if (this.swiper) {
        this.update()
      }
    })
  },
  mounted() {
    this.mountInstance()
  },
  updated() {
    this.update()
  },
  beforeDestroy() {
    this.$nextTick(() => {
      if (this.swiper) {
        this.swiper.destroy && this.swiper.destroy()
        delete this.swiper
      }
    })
  },
  methods: {
    bindEvents() {
      const vm = this
      DEFAULT_EVENTS.forEach((eventName) => {
        this.swiper.on(eventName, function() {
          vm.$emit(eventName, ...arguments)
          vm.$emit(
            eventName.replace(/([A-Z])/g, '-$1').toLowerCase(),
            ...arguments,
          )
        })
      })
    },
    mountInstance() {
      this.swiperOptions = Object.assign({}, this.defaultOptions, this.options)
      this.swiper = new Swiper(this.$el, this.swiperOptions)
      this.bindEvents()

      this.swiper.on('init', () => {
        this.$emit('ready', this.swiper)
      })
      this.swiper.init()
    },
    reset() {
      if (this.swiper) {
        this.swiper.destroy(true, true)
        this.swiper = null
      }
      this.$nextTick(() => {
        this.mountInstance()
      })
    },
    update() {
      this.$nextTick(() => {
        if (this.swiper) {
          this.swiper.update()
          this.swiper.navigation.update()
          this.swiper.pagination.render()
          this.swiper.pagination.update()
        }
      })
    },
  },
}
</script>

<style lang="scss">
.Swiper {
}
</style>
