import { mapState, mapMutations, mapActions } from 'vuex'

export default {
  computed: {
    ...mapState('breakpoints', ['device']),
    ...mapState('modals', ['open']),
  },
  watch: {
    open() {
      if (!this.open) {
        this.pushPageLoad()
      }
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.setModalOpen(to.name !== 'home')
      vm.updateWindowHeight(window.innerHeight)
    })
  },
  beforeRouteUpdate(to, from, next) {
    this.setModalOpen(to.name !== 'home')
    this.updateWindowHeight(window.innerHeight)
    next()
  },
  created() {
    this.pushPageLoad()
  },
  methods: {
    ...mapActions('breakpoints', ['updateWindowHeight']),
    ...mapMutations('modals', ['setModalOpen']),
    pushPageLoad() {
      const name = this.$options.name.toLowerCase()
      if (this.$route.name === name) {
        this.$gtm.pushPageLoad({
          name: this.$route.name,
          language: 'fr',
          path: this.$route.path,
          responsive: this.device,
        })
      }
    },
  },
}
