<template>
  <div class="TheFoundationPillar">
    <div class="TheFoundationPillar__Illu">
      <img :src="illu" :alt="`pillar_${index}`" />
    </div>
    <div class="TheFoundationPillar__Content">
      <h2 v-html="title" />
      <div v-if="description">
        <p v-text="description.text" />
      </div>
      <div v-if="features">
        <h3 v-if="features.title" v-html="features.title" />
        <p v-text="features.text" />
      </div>
    </div>
    <div
      v-if="isDesktop"
      class="TheFoundationPillar__Index"
      v-text="displayedIndex"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'TheFoundationPillar',
  props: {
    index: {
      type: Number,
      required: true,
    },
    illu: {
      type: String,
      required: true,
    },
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['name', 'title', 'items'].every((key) => key in value)
      },
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    displayedIndex() {
      return `0${this.index}`
    },
    name() {
      return this.content.name
    },
    title() {
      return this.content.title
    },
    items() {
      return this.content.items
    },
    features() {
      return this.items[0] || null
    },
    description() {
      return this.items[1] || null
    },
  },
}
</script>

<style lang="scss">
.TheFoundationPillar {
  @include md-down {
    @include grid-col(12);
    flex-direction: column;
    align-items: center;
    padding: $spacer * 1.25;

    &:nth-of-type(even) {
      background-color: rgba($color-secondary, 0.05);
    }
  }
  @include lg-up {
    @include grid-col(6);
    padding-bottom: $gutter * 2;
    align-items: flex-start;
  }

  position: relative;
  display: flex;
  justify-content: flex-start;

  // Illu

  &__Illu {
    @include md-down {
      width: 40px;
      padding-bottom: $spacer;
    }
    @include lg-up {
      padding-right: 50px;
    }

    position: relative;
    z-index: 1;
    flex: 0 0 auto;
  }

  // Content

  &__Content {
    @include md-down {
      padding: 0 $spacer;
      text-align: center;
    }
    @include lg-up {
      padding-right: $spacer;
    }

    position: relative;
    z-index: 1;
    flex: 0 1 auto;

    h2 {
      margin-bottom: $spacer;

      font-size: 3.2rem;
      color: $color-primary;
    }

    > div {
      &:not(:last-of-type) {
        padding-bottom: $spacer;
      }

      h3 {
        margin-bottom: 7px;

        font-family: $font-family;
        font-size: 1rem;
        font-weight: 600;
        color: $color-primary;
        text-transform: uppercase;
        letter-spacing: 2px;
      }

      p {
        font-weight: 300;
        color: $color-secondary;
      }
    }
  }

  // Index

  &__Index {
    @include md-down {
      display: none;
    }
    @include lg-up {
      position: absolute;
      bottom: 20px;
      right: 0;
      z-index: 0;

      font-size: 20rem;
      font-weight: 800;
      line-height: 1;
      color: $color-foreground;
    }
  }
}
</style>
