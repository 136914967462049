const state = {
  open: false,
}

const mutations = {
  setModalOpen: (state, boolean) => {
    state.open = boolean
  },
}

export default {
  state,
  mutations,
  namespaced: true,
}
