import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home'
import About from '@/views/About'
import History from '@/views/History'
import Foundation from '@/views/Foundation'
import Legal from '@/views/Legal'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home,
    children: [
      {
        path: 'about',
        name: 'about',
        component: About,
        redirect: { name: 'history' },
        children: [
          {
            path: 'history',
            name: 'history',
            component: History,
          },
          {
            path: 'foundation',
            name: 'foundation',
            component: Foundation,
          },
        ],
      },
      {
        path: 'legal',
        name: 'legal',
        component: Legal,
      },
    ],
  },

  {
    path: '*',
    name: 'error',
    redirect: '/',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
