<template>
  <div class="TheCookiebar">
    <div class="TheCookiebar__Inner">
      <div class="TheCookiebar__Text">
        <span v-html="text" />
        &nbsp;
        <router-link :to="{ name: link.route }" v-text="link.text" />
      </div>
      <BaseLink
        class="TheCookiebar__Button"
        :route="cta.route"
        :text="cta.text"
        @click.native="handleClick"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getCookie, createCookie } from '@/helpers/cookies'
import BaseLink from '@/components/BaseLink'

export default {
  name: 'TheCookiebar',
  components: {
    BaseLink,
  },
  computed: {
    ...mapGetters('api', {
      content: 'getContent',
    }),
    text() {
      return this.content.cookiebar.text
    },
    link() {
      return this.content.cookiebar.link
    },
    cta() {
      return this.content.cookiebar.cta
    },
    cookieExist() {
      return getCookie('cookies-accept')
    },
  },
  created() {
    if (this.cookieExist) this.$emit('hide')
  },
  methods: {
    handleClick() {
      this.createCookie()
      this.$emit('hide')
    },
    createCookie() {
      createCookie('cookies-accept', true, 30)
    },
  },
}
</script>

<style lang="scss">
.TheCookiebar {
  @include section;
  @include responsive-property(padding-top, $spacer/2, $spacer);
  @include responsive-property(padding-bottom, $spacer/2, $spacer);
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  z-index: $index-cookiebar;

  background-color: $color-foreground;

  // Inner

  &__Inner {
    @include inner;
    @include md-down {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
    @include lg-up {
      justify-content: space-between;
      align-items: center;
    }
    display: flex;
  }

  // Text

  &__Text {
    @include responsive-property(font-size, 11px, 13px);
    @include lg-up {
      margin-right: $spacer;
    }

    flex: 0 1 auto;

    a {
      @include set-underline($color-primary);
      color: $color-primary;
    }
  }

  // BaseLink

  .BaseLink {
    @include md-down {
      margin-top: $spacer/2;
      width: 100%;
      justify-content: space-between;
    }
    flex: 0 0 auto;

    &__Text {
      // @include responsive-property(font-size, 10px, 11px);
    }
  }
}
</style>
