<template>
  <div :class="classNames">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'GridRow',
  computed: {
    classNames() {
      return ['GridRow']
    },
  },
}
</script>

<style lang="scss">
.GridRow {
  @include grid-row;
}
</style>
