<template>
  <div class="ProductItem">
    <div class="ProductItem__Image">
      <div>
        <BasePromo v-if="promo" size="small" />
        <img :src="image" :alt="name" width="370" />
      </div>
    </div>
    <div class="ProductItem__Content">
      <BaseHeading :level="3" :suptitle="type" :title="name" />
      <div class="ProductItem__Prices">
        <BasePrice
          v-if="priceOriginalAmount !== priceDiscountAmount"
          :amount="priceOriginalAmount"
          :currency="priceOriginalCurrency"
          strike
          small
        />
        <BasePrice
          :amount="priceDiscountAmount"
          :currency="priceDiscountCurrency"
        />
      </div>
    </div>
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading'
import BasePrice from '@/components/BasePrice'
import BasePromo from '@/components/BasePromo'

export default {
  name: 'ProductItem',
  components: {
    BaseHeading,
    BasePrice,
    BasePromo,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: '',
    },
    name: {
      type: String,
      required: true,
    },
    prices: {
      type: Object,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    promo: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    priceOriginalAmount() {
      return this.prices.original.amount
    },
    priceOriginalCurrency() {
      return this.prices.original.currency
    },
    priceDiscountAmount() {
      return this.prices.discount
        ? this.prices.discount.amount
        : this.prices.original.amount
    },
    priceDiscountCurrency() {
      return this.prices.discount
        ? this.prices.discount.currency
        : this.prices.original.currency
    },
  },
}
</script>

<style lang="scss">
.ProductItem {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;

  // Image

  &__Image {
    @include aspect-ratio(285px, 380px);
    background-color: shade(#f8f8fa, 2%);
    flex: 0 1 auto;
    .BasePromo {
      @include sm {
        top: 30%;
        right: 25%;
      }
      @include md {
        top: 30%;
        right: 25%;
      }
      @include lg-up {
        top: 30%;
        right: 25%;
      }

      position: absolute;
    }

    img {
      width: 100%;
    }
  }

  // Content

  &__Content {
    @include md-down {
      flex-direction: column;
      justify-content: space-between;
      flex: 1 1 auto;
    }

    padding-top: $spacer;
    padding-bottom: $spacer;
    display: flex;
    align-items: stretch;

    .BaseHeading {
      flex: 1 1 auto;
      padding-bottom: 0;
    }
  }

  &__Prices {
    @include md-down {
      padding-top: 15px;
    }

    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    align-item: flex-end;
    flex: 0 0 auto;

    .BasePrice {
      @include lg-up {
        align-self: flex-end;
      }
      flex: 0 0 auto;
      line-height: 1;

      span {
        &:first-of-type {
          font-size: 23px;
          font-weight: 700;
        }
        &:last-of-type {
          font-size: 14px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }

      &.is-strike span {
        font-size: 15px;
      }
    }

    // Price

    &__Price {
      color: $color-primary;
    }
  }
}
</style>
