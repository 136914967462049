<template>
  <div class="TheAboutContent">
    <BaseHeading
      level="2"
      class="TheAboutContent__Heading"
      :suptitle="suptitle"
      :title="title"
    />
    <BaseText class="TheAboutContent__Text" :text="text" />
    <BaseLink
      class="TheAboutContent__Button"
      :route="{ name: 'about' }"
      :text="cta.text"
    />
  </div>
</template>

<script>
import BaseHeading from '@/components/BaseHeading'
import BaseLink from '@/components/BaseLink'
import BaseText from '@/components/BaseText'

export default {
  name: 'TheAboutContent',
  components: {
    BaseHeading,
    BaseLink,
    BaseText,
  },
  props: {
    suptitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    cta: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['text'].every((key) => key in value)
      },
    },
  },
}
</script>

<style lang="scss">
.TheAboutContent {
  @include md-down {
    @include grid-col(12);
    padding-left: $gutter;
    padding-right: $gutter;
  }
  @include lg-up {
    @include grid-col(4.5);
    @include grid-offset-left(1);
    padding: $spacer * 2 0;
  }

  flex: 0 1 auto;

  // Heading

  &__Heading {
    width: 80%;
  }

  // Text

  &__Text {
  }
}
</style>
