<template>
  <section id="contact" class="TheContact">
    <div class="TheContact__Inner">
      <div class="TheContact__Panel">
        <TheContactTrigger
          id="TheContactTrigger"
          ref="trigger"
          :title="title"
          :cta="cta"
          :active="showContactForm"
          @click.native="handleClick"
        />
        <SlideUpDown :active="showContactForm">
          <TheContactForm
            ref="form"
            :fields="fields"
            :notifications="notifications"
            :submit="submit"
            @close="handleClose"
          />
        </SlideUpDown>
      </div>
    </div>
  </section>
</template>

<script>
import { wait } from '@/helpers/time'
import { mapState, mapMutations, mapActions } from 'vuex'
import SlideUpDown from '@/components/SlideUpDown'
import TheContactForm from './components/TheContactForm'
import TheContactTrigger from './components/TheContactTrigger'

export default {
  name: 'TheContact',
  components: {
    SlideUpDown,
    TheContactForm,
    TheContactTrigger,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'fields', 'cta', 'submit'].every((key) => key in value)
      },
    },
  },
  computed: {
    ...mapState('page', ['selectedShop', 'showContactForm']),
    title() {
      return this.content.title
    },
    fields() {
      return this.content.fields
    },
    cta() {
      return this.content.cta
    },
    notifications() {
      return this.content.notifications
    },
    submit() {
      return this.content.submit
    },
  },
  methods: {
    ...mapMutations('page', ['setShowContactForm']),
    ...mapActions('page', ['scrollToContactForm']),
    handleClick() {
      if (this.showContactForm) this.handleClose()
      else this.scrollToContactForm()
    },
    handleClose() {
      this.setShowContactForm(false)
      wait(600, () => {
        this.$refs.form.reset()
      })
    },
  },
}
</script>

<style lang="scss">
.TheContact {
  @include section;
  position: relative;
  z-index: $index-contact;

  // Inner

  &__Inner {
    @include inner;
  }

  // Panel

  &__Panel {
    @include transition(background-color);
    @include hover {
      .TheContactTrigger {
        background-color: tint($color-primary, 10%);
      }

      background-color: tint($color-primary, 10%);
    }

    width: 100%;
    max-width: 485px;

    position: absolute;
    top: 0;
    right: 0;
    will-change: background-color;
    background-color: $color-primary;
  }
}
</style>
