<template>
  <div class="Legal">
    <div class="Legal__Content">
      <div v-bar class="Legal__Content__ScrollContainer">
        <div
          v-scroll-lock="open"
          class="Legal__Content__Inner"
          :style="innerStyles"
        >
          <TheLegal :title="title" :text="text" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import TheLegal from '@/components/TheLegal'
import { Views } from '@/mixins'

export default {
  name: 'Legal',
  components: {
    TheLegal,
  },
  mixins: [Views],
  computed: {
    ...mapGetters('api', {
      content: 'getContent',
    }),
    ...mapState('modals', ['open']),
    ...mapState('breakpoints', ['height']),
    ...mapGetters('breakpoints', ['isMobile']),
    text() {
      return this.content.legal.text
    },
    title() {
      return this.content.legal.title
    },
    innerStyles() {
      return this.isMobile
        ? `max-height: ${this.height - 140 - 30}px;`
        : `max-height: ${this.height - 200}px;`
    },
  },
}
</script>

<style lang="scss">
.Legal {
  // Content

  &__Content {
    $padding-desktop: $spacer * 2;
    padding-right: 5px;

    &__Inner {
      @include md-down {
        max-height: calc(100vh - 140px - 30px);
      }
      @include lg-up {
        max-height: calc(100vh - 200px);
      }

      position: relative;
      box-sizing: border-box;
    }

    // ScrollContainer

    &__ScrollContainer {
      @include scroll-gradients(white, $padding-desktop);
      width: 100%;
    }
  }
}
</style>
