<template>
  <div :class="classNames">
    <img :src="src" :alt="alt" />
    <div class="BaseImage__Container">
      <div class="BaseImage__Background" :style="customStyle" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'BaseImage',
  props: {
    src: {
      type: String,
      required: true,
    },
    alt: {
      type: String,
      required: false,
      default: null,
    },
    position: {
      type: String,
      default: 'center',
    },
  },
  computed: {
    classNames() {
      return ['BaseImage']
    },
    customStyle() {
      return `
        background-image: url(${this.src});
        background-position: ${this.position} ;
      `
    },
  },
}
</script>

<style lang="scss">
.BaseImage {
  width: 100%;
  height: 100%;

  // Img

  img {
    @include hide-visually;
  }

  // Container

  &__Container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
  }

  // Background

  &__Background {
    @include center-abs;
    z-index: 1;
    width: 120%;
    height: 120%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
  }
}
</style>
