<template>
  <div :class="classNames">
    <div
      v-rellax="rellaxOptions"
      class="BaseForeground__Bunch"
      :style="backgroundImage"
    ></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ForegroundAbout from '@/assets/images/foreground_about.svg'
import ForegroundOffers from '@/assets/images/foreground_offers.svg'
import ForegroundShops from '@/assets/images/foreground_map.svg'

export default {
  name: 'BaseForeground',
  props: {
    theme: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    classNames() {
      return ['BaseForeground', `BaseForeground--${this.theme}`]
    },
    backgroundImage() {
      const url = this.getImageUrl()
      if (url) return `background-image: url(${url})`
      return ''
    },
    rellaxOptions() {
      return this.isDesktop ? { speed: -5, center: true } : false
    },
  },
  methods: {
    getImageUrl() {
      switch (this.theme) {
        case 'about':
          return ForegroundAbout
        case 'offers':
          return ForegroundOffers
        case 'shops':
          return ForegroundShops
        default:
          return ''
      }
    },
  },
}
</script>

<style lang="scss">
.BaseForeground {
  position: absolute;

  top: 0;
  z-index: -1;

  opacity: 0.02;

  // Bunch

  &__Bunch {
    @include fit;
    background-size: contain;
    background-repeat: no-repeat;
  }

  // Themes

  // Themes | About

  &--about {
    @include responsive-property(width, 1051px, 1577px);
    @include responsive-property(height, 629px, 1254px);

    transform: translate(78%, -10%);
    left: 0;
  }

  // Themes | Offers

  &--offers {
    @include responsive-property(width, 1051px, 1577px);
    @include responsive-property(height, 629px, 943px);
    @include md-down {
      display: none;
    }
    @include lg-up {
      left: 0;
      transform: translate(-75%, 40%);
    }
  }

  // Themes | Map

  &--shops {
    @include responsive-property(width, 1051px, 1577px);
    @include responsive-property(height, 629px, 1254px);
    @include md-down {
      transform: translate(20%, 80%);
    }
    @include lg-up {
      right: 0;
      transform: translate(25%, -10%);
    }
  }
}
</style>
