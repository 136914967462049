<template>
  <ValidationObserver v-slot="{ passes }" slim>
    <form :class="classNames" @submit.prevent="passes(handleSubmit)">
      <fieldset class="TheContactForm__Fieldset">
        <FormInput
          v-model="name.value"
          :name="fields.name.name"
          :label="fields.name.label"
          :placeholder="fields.name.placeholder"
          :rules="name.rules"
        />

        <FormInput
          v-model="email.value"
          type="email"
          :name="fields.email.name"
          :label="fields.email.label"
          :placeholder="fields.email.placeholder"
          :rules="email.rules"
        />

        <FormInput
          v-model="phone.value"
          type="phone"
          :name="fields.phone.name"
          :label="fields.phone.label"
          :placeholder="fields.phone.placeholder"
          :rules="phone.rules"
        />

        <FormInput
          v-model="mobilePhone.value"
          type="phone"
          :name="fields.mobilePhone.name"
          :label="fields.mobilePhone.label"
          :placeholder="fields.mobilePhone.placeholder"
          :rules="mobilePhone.rules"
        />

        <FormSelect
          v-model="shop.value"
          :name="fields.shop.name"
          :label="fields.shop.label"
          :options="fields.shop.options"
          :rules="shop.rules"
        />

        <FormTextarea
          v-model="message.value"
          :name="fields.message.name"
          :label="fields.message.label"
          :placeholder="fields.message.placeholder"
          :rules="message.rules"
        />

        <FormCheckbox
          v-model="gdpr.value"
          :name="fields.gdpr.name"
          :label="fields.gdpr.label"
          :rules="gdpr.rules"
        />

        <FormCheckbox
          v-model="newsletter.value"
          :name="fields.newsletter.name"
          :label="fields.newsletter.label"
          :rules="newsletter.rules"
        />
      </fieldset>

      <button class="TheContactForm__Submit" type="submit">
        <span>{{ submit }}</span>
      </button>
    </form>
  </ValidationObserver>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import { ValidationObserver } from 'vee-validate'
import FormInput from '@/components/FormInput'
import FormSelect from '@/components/FormSelect'
import FormCheckbox from '@/components/FormCheckbox'
import FormTextarea from '@/components/FormTextarea'

export default {
  name: 'TheContactForm',
  components: {
    FormInput,
    FormSelect,
    FormCheckbox,
    FormTextarea,
    ValidationObserver,
  },
  props: {
    notifications: {
      type: Object,
      required: false,
      default: () => {
        return {
          title: 'Titre par défaut',
          success: 'Message de succès par défaut',
          fail: "Message d'erreur par défaut",
        }
      },
      validator: (value) => {
        return ['success', 'fail', 'title'].every((key) => key in value)
      },
    },
    fields: {
      type: Object,
      required: true,
      validator: (value) => {
        return [
          'name',
          'email',
          'phone',
          'mobilePhone',
          'shop',
          'message',
          'gdpr',
          'newsletter',
        ].every((key) => key in value)
      },
    },
    submit: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: {
        value: '',
        rules: 'required',
      },
      email: {
        value: '',
        rules: 'required|email',
      },
      phone: {
        value: '',
        rules: '',
      },
      mobilePhone: {
        value: '',
        rules: '',
      },
      shop: {
        value: '',
        rules: 'required',
      },
      message: {
        value: '',
        rules: 'required',
      },
      gdpr: {
        value: false,
        rules: {
          required: {
            allowFalse: false,
          },
        },
      },
      newsletter: {
        value: false,
        rules: '',
      },
    }
  },
  computed: {
    ...mapState('page', ['selectedShop']),
    classNames() {
      return ['TheContactForm']
    },
  },
  watch: {
    selectedShop() {
      this.shop.value = this.selectedShop
    },
  },
  methods: {
    ...mapActions('api', ['postContact']),
    handleSubmit() {
      this.postContact({
        name: this.name.value,
        email: this.email.value,
        phone: this.phone.value,
        mobilePhone: this.mobilePhone.value,
        shop: this.shop.value,
        message: this.message.value,
        gdpr: this.gdpr.value,
        newsletter: this.newsletter.value,
      })
        .then(() => {
          this.notify(
            this.notifications.title,
            this.notifications.success,
            'success',
          )
          this.$gtm.pushContact()
          this.$emit('close')
        })
        .catch((e) => {
          this.notify(
            this.notifications.title,
            `${this.notifications.fail} - ${e.message}`,
            'error',
          )
        })
    },
    notify(title, text, type) {
      this.$notify({
        type,
        title,
        text,
        speed: 500,
        duration: 10000,
        classes: 'notification',
      })
    },
    reset() {
      this.name.value = ''
      this.email.value = ''
      this.phone.value = ''
      this.mobilePhone.value = ''
      this.shop.value = ''
      this.message.value = ''
      this.gdpr.value = false
      this.newsletter.value = false
    },
  },
}
</script>

<style lang="scss">
.TheContactForm {
  @include md-down {
    padding: 0 30px;
  }
  @include lg-up {
    padding: 0 50px;
  }

  // Fieldset

  &__Fieldset {
    padding-bottom: $spacer;
  }

  // Submit

  &__Submit {
    @include transition(background-color);
    @include md-down {
      width: calc(100% + 60px);
      margin-left: -30px;
      margin-right: -30px;
    }
    @include lg-up {
      width: calc(100% + 100px);
      margin-left: -50px;
      margin-right: -50px;
    }
    @include hover {
      background-color: tint($color-secondary, 5%);
    }

    padding: $spacer;

    background-color: $color-secondary;

    color: white;
    font-size: 1.2rem;
    font-weight: 700;
    letter-spacing: 3px;
    text-transform: uppercase;

    cursor: pointer;
    will-change: background-color;
  }
}
</style>
