<template>
  <ValidationProvider
    v-slot="{ errors, invalid }"
    slim
    :rules="rules"
    :name="label"
  >
    <div :class="[classNames, { 'is-invalid': invalid }]">
      <label class="FormTextarea__Label" :for="name">
        <span v-text="label"></span>
        <textarea
          :id="name"
          v-model="fieldValue"
          class="FormTextarea__Field"
          :type="type"
          :placeholder="placeholder"
        />
      </label>
      <FormError class="FormTextarea__Error" :errors-bag="errors" />
    </div>
  </ValidationProvider>
</template>

<script>
import FormError from '@/components/FormError'
import { ValidationProvider } from 'vee-validate'
// import { required } from 'vee-validate/dist/rules'

export default {
  name: 'FormTextarea',
  components: {
    FormError,
    ValidationProvider,
  },
  props: {
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: true,
    },
    rules: {
      type: [String, Object],
      required: true,
    },
    theme: {
      type: String,
      default: 'contact',
    },
  },
  data() {
    return {
      fieldValue: '',
    }
  },
  computed: {
    classNames() {
      return ['FormTextarea', `FormTextarea--${this.theme}`]
    },
  },
  watch: {
    value() {
      this.fieldValue = this.value
    },
    fieldValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.fieldValue = this.value
  },
}
</script>

<style lang="scss">
.FormTextarea {
  // Label

  &__Label {
    display: block;

    > span {
      @include responsive-property(font-size, 15px, 17px);
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  // Field

  &__Field {
    width: 100%;
    height: 160px;
    outline: none;
    padding: 20px $spacer/2;

    &::-ms-clear {
      display: none;
    }
  }

  // Themes

  // Themes | Contact

  &--contact {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(white, 0.4);

    .FormTextarea__Field {
      @include placeholder;
      border-radius: 5px;
      color: white;
      background-color: rgba(white, 0.2);
    }

    .FormTextarea__Label {
      color: white;
    }
  }

  // Themes | Newsletter

  &--newsletter {
    .FormTextarea__Field {
      @include placeholder(shade($color-placeholder, 15%));
      font-size: 1.8rem;
      font-weight: 300;
      padding-left: 0;
      color: $color-tertiary;
    }
  }
}
</style>
