import Vue from 'vue'
import Vuex from 'vuex'

import api from './_modules/api'
import breakpoints from './_modules/breakpoints'
import modals from './_modules/modals'
import page from './_modules/page'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    api,
    breakpoints,
    modals,
    page,
  },
  strict: true,
})
