<template>
  <div class="TheFooterLogo">
    <img :src="logo" :alt="text" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoMobile from '@/assets/images/logo_mobile.svg'
import LogoDesktop from '@/assets/images/footer_logo_desktop.svg'

export default {
  name: 'TheFooterLogo',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    logo() {
      return this.isDesktop ? LogoDesktop : LogoMobile
    },
  },
}
</script>

<style lang="scss">
.TheFooterLogo {
  @include md-down {
    padding-top: $spacer/2;
    padding-bottom: $spacer/2;
  }
  flex: 0 1 auto;

  img {
    @include lg-up {
      width: 100%;
      max-width: 344px;
    }
  }
}
</style>
