<template>
  <section id="bestsellers" class="TheBestsellers">
    <div class="TheBestsellers__Inner">
      <GridRow class="TheBestsellers__Row">
        <div class="TheBestsellers__Head">
          <BaseHeading :suptitle="suptitle" :title="title" />
          <FiltersList :filters="filters" @update="handleFiltersUpdate" />
        </div>
        <div class="TheBestsellers__Content">
          <transition
            name="custom-classes-transition"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div v-show="slides.length > 0">
              <Swiper ref="swiperComponent" :options="swiperOptions">
                <SwiperSlide
                  v-for="(item, index) in slides"
                  :key="`slide-${index}`"
                >
                  <ProductItem
                    :type="item.type"
                    :name="item.name"
                    :prices="item.prices"
                    :image="item.image"
                    :promo="item.promo"
                  />
                </SwiperSlide>
              </Swiper>
              <div ref="pagination" class="swiper-pagination" />
            </div>
          </transition>
          <transition
            name="custom-classes-transition"
            enter-active-class="animated fadeIn"
            leave-active-class="animated fadeOut"
          >
            <div
              v-show="slides.length === 0"
              class="TheBestsellers__Error"
              v-text="error"
            />
          </transition>
        </div>
      </GridRow>
    </div>
  </section>
</template>

<script>
import { cloneDeep } from 'lodash'
import BaseHeading from '@/components/BaseHeading'
import FiltersList from '@/components/FiltersList'
import GridRow from '@/components/GridRow'
import ProductItem from '@/components/ProductItem'
import Swiper from '@/components/Swiper'
import SwiperSlide from '@/components/SwiperSlide'

export default {
  name: 'TheBestsellers',
  components: {
    BaseHeading,
    FiltersList,
    GridRow,
    ProductItem,
    Swiper,
    SwiperSlide,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'error', 'filters', 'items'].every(
          (key) => key in value,
        )
      },
    },
  },
  data() {
    return {
      slides: [],
      swiperOptions: {
        spaceBetween: 30,
        pagination: {},
        breakpoints: {
          0: {
            slidesPerView: 1.2,
          },
          768: {
            slidesPerView: 2.2,
          },
          1080: {
            slidesPerView: 3,
          },
        },
      },
    }
  },
  computed: {
    suptitle() {
      return this.content.suptitle || ''
    },
    title() {
      return this.content.title
    },
    error() {
      return this.content.error
    },
    filters() {
      return this.content.filters
    },
    items() {
      return this.content.items
    },
  },
  created() {
    this.slides = cloneDeep(this.items)
  },
  mounted() {
    this.$set(this.swiperOptions, 'pagination', {
      el: this.$refs.pagination,
      clickable: true,
    })
    this.$nextTick(() => {
      this.$refs.swiperComponent.reset()
    })
  },
  methods: {
    handleFiltersUpdate(category) {
      this.slides = this.items.filter((item) => {
        if (category !== 'all' && item.category === category) {
          return item
        } else if (category === 'all') return item
      })
    },
  },
}
</script>

<style lang="scss">
.TheBestsellers {
  @include section;
  @include responsive-property(padding-top, 30px, 120px);
  @include responsive-property(padding-bottom, 30px, 60px);

  @include md-down {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  position: relative;
  z-index: $index-bestsellers;

  // Inner

  &__Inner {
    @include inner;
  }

  // Head

  &__Head {
    @include grid-col(12);
    @include md-down {
      flex-direction: column;
      justify-content: flex-start;
      align-items: flex-start;
    }
    @include lg-up {
      justify-content: space-between;
      align-items: flex-end;
    }

    display: flex;

    .BaseHeading {
      flex: 1 1 auto;
    }

    .FiltersList {
      @include md-down {
        flex: 1 1 auto;
        width: 100%;
      }
      @include lg-up {
        flex: 0 0 auto;
      }
      padding-bottom: $spacer;
    }
  }

  // Content

  &__Content {
    @include grid-col(12);

    // Swiper container

    .swiper-container {
      overflow: visible;
    }

    // Swiper wrapper

    .swiper-wrapper {
      align-items: stretch;
      .swiper-slide {
        height: auto;
      }
    }

    // Swiper Pagination

    .swiper-pagination {
      @include md-down {
        padding-top: $spacer/2;
      }
      @include lg-up {
        padding-top: $spacer;
      }
      position: static;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
    }

    // Swiper Pagination Bullet

    .swiper-pagination-bullet {
      @include transition;
      @include size(10px);

      flex: 0 1 auto;
      margin: 0 8px !important;
      opacity: 1;
      background-color: shade($color-primary, 20%);
      border-width: 2px;
      border-style: solid;
      border-color: white;
      cursor: pointer;
      outline: none;

      &-active {
        @include size(10px);
        border-color: shade($color-primary, 20%);
        background-color: transparent;
      }
    }
  }

  // Error

  &__Error {
    padding-top: $spacer;
    text-align: center;
  }
}
</style>
