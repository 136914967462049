<template>
  <div :class="classNames" v-html="displayedPrice" />
</template>

<script>
export default {
  name: 'BasePrice',
  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
    strike: {
      type: Boolean,
      default: false,
    },
    small: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classNames() {
      return [
        'BasePrice',
        { 'is-strike': this.strike },
        { 'is-small': this.small },
      ]
    },
    displayedPrice() {
      return `<span>${this.amount.toLocaleString('fr-FR')}</span>&nbsp;<span>${this.currency}</span>`
    },
  },
}
</script>

<style lang="scss">
.BasePrice {
  color: $color-primary;
  display: flex;
  justify-content: flex-start;
  align-items: baseline;

  span {
    flex: 0 1 auto;

    &:first-of-type {
      font-size: 3.2rem;
      font-weight: 700;
    }
    &:last-of-type {
      font-size: 2.1rem;
      font-weight: 600;
      text-transform: uppercase;
    }
  }

  // Strike

  &.is-strike {
    color: $color-secondary;
    text-decoration-line: line-through;
  }

  // Small

  &.is-small {
    span {
      &:first-of-type {
        font-size: 1.9rem;
        font-weight: 700;
      }
      &:last-of-type {
        font-size: 1.3rem;
        font-weight: 600;
        text-transform: uppercase;
      }
    }
  }

  &--normal {
  }
}
</style>
