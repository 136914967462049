<template>
  <div class="BasePromo" :class="customClass">
    <div>
      <img :src="promoImage" alt="Promotion 6 + 1" />
    </div>
  </div>
</template>

<script>
import Promo from '@/assets/images/promo_6_1.png'
export default {
  name: 'BasePromo',
  props: {
    size: {
      type: String,
      required: false,
      default: 'large',
    },
  },
  data() {
    return {
      promoImage: Promo,
    }
  },
  computed: {
    customClass() {
      return [`BasePromo--${this.size}`]
    },
  },
}
</script>

<style lang="scss">
.BasePromo {
  @include aspect-ratio(140px, 140px);
  &--small {
    @include sm {
      width: 30%;
    }
    @include md {
      width: 30%;
    }
    @include lg-up {
      width: 96px;
    }
  }
  &--large {
    @include sm {
      width: 20%;
    }
    @include md {
      width: 20%;
    }
    @include lg-up {
      width: 117px;
    }
  }

  img {
    width: 100%;
    object-fit: cover;
  }
}
</style>
