<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    role="img"
    aria-label="Loading.."
    width="32"
    height="32"
    class="BaseSpinner"
    viewBox="0 0 32 32"
  >
    <circle
      class="BaseSpinner__circle"
      cx="16"
      cy="16"
      r="13"
      fill="none"
      stroke="rgba(0, 0, 0, 0.9)"
      stroke-width="3"
      stroke-linecap="round"
      stroke-dasharray="55.28"
    />
  </svg>
</template>

<script>
export default {
  name: 'BaseSpinner',
}
</script>

<style lang="scss">
.BaseSpinner {
  transition-property: transform;
  animation-name: spin;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
  animation-duration: 1.25s;

  &__circle {
    stroke: $color-primary;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}
</style>
