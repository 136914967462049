<template>
  <div class="SwiperSlide" :class="slideClass">
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'SwiperSlide',
  data() {
    return {
      slideClass: 'swiper-slide',
    }
  },
  created() {
    this.update()
  },
  mounted() {
    this.update()
    if (
      this.$parent &&
      this.$parent.options &&
      this.$parent.options.slideClass
    ) {
      this.slideClass = this.$parent.options.slideClass
    }
  },
  updated() {
    this.update()
  },
  methods: {
    update() {
      if (this.$parent && this.$parent.swiper) {
        this.$parent.update()
      }
    },
  },
}
</script>

<style lang="scss">
.SwiperSlide {
  &.swiper-slide {
    width: auto;
  }
}
</style>
