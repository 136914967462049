<template>
  <section id="shops" class="TheShops">
    <BaseForeground theme="shops" />
    <div class="TheShops__Inner">
      <div class="TheShops__List">
        <TheShopsList :items="items" @change="handleChange" />
      </div>
      <div class="TheShops__Map">
        <TheShopsMap
          :markers="markers"
          :current-index="currentIndex"
          @change="handleChange"
        />
      </div>
    </div>
  </section>
</template>

<script>
import BaseForeground from '@/components/BaseForeground'
import TheShopsList from './components/TheShopsList'
import TheShopsMap from './components/TheShopsMap'

export default {
  name: 'TheShops',
  components: {
    BaseForeground,
    TheShopsList,
    TheShopsMap,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['items'].every((key) => key in value)
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
    }
  },
  computed: {
    items() {
      return this.content.items
    },
    markers() {
      return this.content.items.map((item) => {
        return {
          position: item.coordinates,
        }
      })
    },
  },
  methods: {
    handleChange(index) {
      this.currentIndex = index
    },
  },
}
</script>

<style lang="scss">
.TheShops {
  @include section;
  @include responsive-property(padding-top, 30px, 60px);
  @include sm {
    margin-left: -$spacer/2;
    margin-right: -$spacer/2;
    width: calc(100% + #{$spacer});
  }
  @include md {
    margin-left: -$spacer;
    margin-right: -$spacer;
    width: calc(100% + (#{$spacer} * 2));
  }
  position: relative;
  z-index: $index-shops;

  // Inner

  &__Inner {
    @include inner;
    @include md-down {
      align-items: flex-start;
      flex-direction: column;
    }
    @include lg-up {
      align-items: flex-start;
    }

    position: relative;
    z-index: 1;

    display: flex;
    justify-content: flex-start;
  }

  // List

  &__List {
    @include md-down {
      width: 100%;
    }
    @include lg-up {
      @include center-abs(vertical);
      // position: absolute;
      z-index: 1;
      // height: 100%;
      width: 50%;
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }
  }

  // Map

  &__Map {
    $diff-page: ($page-max - $page-width) / 2;
    @include md-down {
      @include aspect-ratio(320, 280);
      max-height: 600px;
    }
    @include lg-up {
      position: relative;
      z-index: 0;
      height: 680px;
      margin-right: -$diff-page;
      margin-left: convertify(2);
      width: calc(#{convertify(10)} + #{$diff-page});
    }

    width: 100%;
  }
}
</style>
