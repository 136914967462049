<template>
  <div class="TheHeaderLogo">
    <img :src="logo" :alt="text" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import LogoMobile from '@/assets/images/logo_mobile.svg'
import LogoDesktop from '@/assets/images/header_logo_desktop.svg'

export default {
  name: 'TheHeaderLogo',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    logo() {
      return this.isDesktop ? LogoDesktop : LogoMobile
    },
  },
}
</script>

<style lang="scss">
.TheHeaderLogo {
  flex: 1 1 auto;

  img {
    @include lg-up {
      max-width: 380px;
    }
    @include xxl {
      max-width: 470px;
    }
  }
}
</style>
