<template>
  <div :class="classNames">
    <span v-if="suptitle" class="BaseHeading__Suptitle" v-text="suptitle" />
    <component :is="tagName" class="BaseHeading__Title" v-html="title" />
  </div>
</template>

<script>
export default {
  name: 'BaseHeading',
  props: {
    level: {
      type: [String, Number],
      required: false,
      default: '2',
    },
    suptitle: {
      type: String,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    theme: {
      type: String,
      default: 'default',
    },
  },
  computed: {
    classNames() {
      return [
        'BaseHeading',
        `BaseHeading--${this.level}`,
        `BaseHeading--${this.theme}`,
      ]
    },
    tagName() {
      return `h${this.level}`
    },
  },
}
</script>

<style lang="scss">
.BaseHeading {
  padding-bottom: $spacer;

  // Suptitle

  &__Suptitle {
    display: block;
    font-weight: 300;
    letter-spacing: 3px;
    color: $color-secondary;
    padding-bottom: $spacer/2;
  }

  // Title

  &__Title {
  }

  // Themes

  // Themes | Default

  &--default {
    .BaseHeading__Title {
      color: $color-primary;

      b,
      strong,
      span {
        color: $color-tertiary;
      }
    }
  }

  // Themes Contact

  &--contact {
    .BaseHeading__Title {
      color: white;

      b,
      strong,
      span {
        color: white;
      }
    }
  }

  // Levels

  // Levels | 2

  &--2 {
    .BaseHeading__Suptitle {
      @include responsive-property(font-size, 14px, 16px);
    }

    .BaseHeading__Title {
      @include responsive-property(font-size, 32px, 42px);
    }
  }

  // Levels | 2

  &--3 {
    .BaseHeading__Suptitle {
      padding-bottom: 0;
      font-size: 1rem;
      font-weight: 600;
      text-transform: uppercase;
    }

    .BaseHeading__Title {
      font-size: 2.8rem;
    }
  }
}
</style>
