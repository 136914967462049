<template>
  <ValidationProvider
    v-slot="{ errors, invalid }"
    slim
    :rules="rules"
    :name="label"
  >
    <div :class="[classNames, { 'is-invalid': invalid }]">
      <label class="FormSelect__Label" :for="name">
        <span v-text="label"></span>
        <select :id="name" v-model="fieldValue" :class="fieldClassNames">
          <option
            v-for="(option, index) in options"
            :key="`option-${index}`"
            :value="option.value"
            :disabled="!option.value"
            v-text="option.text"
          />
        </select>
      </label>
      <FormError class="FormSelect__Error" :errors-bag="errors" />
    </div>
  </ValidationProvider>
</template>

<script>
import FormError from '@/components/FormError'
import { ValidationProvider } from 'vee-validate'
// import { required } from 'vee-validate/dist/rules'

export default {
  name: 'FormSelect',
  components: {
    FormError,
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    options: {
      type: [Object, Array],
      required: false,
      default: () => {
        return []
      },
    },
    value: {
      type: String,
      required: true,
    },
    rules: {
      type: [String, Object],
      required: true,
    },
    theme: {
      type: String,
      default: 'contact',
    },
  },
  data() {
    return {
      fieldValue: null,
    }
  },
  computed: {
    classNames() {
      return ['FormSelect', `FormSelect--${this.theme}`]
    },
    fieldClassNames() {
      return [
        'FormSelect__Field',
        { 'FormSelect__Field--disabled': !this.fieldValue },
      ]
    },
  },
  watch: {
    value() {
      this.fieldValue = this.value
    },
    fieldValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.fieldValue = this.value
  },
}
</script>

<style lang="scss">
.FormSelect {
  // Label

  &__Label {
    display: block;

    > span {
      @include responsive-property(font-size, 15px, 17px);
      display: block;
      font-weight: 600;
      margin-bottom: 5px;
    }
  }

  // Field

  &__Field {
    width: 100%;
    height: 60px;
    outline: none;
    padding: 0 $spacer/2;
    cursor: pointer;
    appearance: none;
    background: none;

    &::-ms-expand {
      display: none;
    }

    option {
      color: $color-font;
    }
  }

  // Themes

  // Themes | Contact

  &--contact {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(white, 0.4);

    .FormSelect__Field {
      border-radius: 5px;
      color: white;
      background-color: rgba(white, 0.2);

      &--disabled {
        color: rgba(white, 0.4);
      }
    }

    .FormSelect__Label {
      color: white;
    }
  }
}
</style>
