import { Provider } from '@/api'

const resource =
  process.env.NODE_ENV === 'development' ? '/newsletter.json' : '/newsletter'

export default {
  post(params) {
    return Provider.post(`${resource}`, params)
  },
}
