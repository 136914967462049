import TransitionReveal from './TransitionReveal'

TransitionReveal.version = '1.0.0'

TransitionReveal.install = (Vue) => {
  Vue.component('TransitionReveal', TransitionReveal)
}

if (typeof window !== 'undefined') {
  window.TransitionReveal = TransitionReveal
}

export default TransitionReveal
