<template>
  <div v-show="swiper" class="TheOffersImages">
    <TheOffersArrows
      v-if="isMobile"
      :index="index"
      :max="maxIndex"
      @change="handleArrowsChange"
    />
    <Swiper
      ref="swiperComponent"
      :options="swiperOptions"
      @ready="handleSwiperReady"
      @slide-change="handleSlideChange"
    >
      <SwiperSlide
        v-for="(item, i) in items"
        :key="`slide-${i}`"
        class="TheOffersImages__Item"
      >
        <BasePromo v-if="item.promo" class="TheOffersImages__Item__Promo" />
        <img :src="item.image" />
      </SwiperSlide>
    </Swiper>
    <nav ref="pagination" class="swiper-pagination"></nav>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swiper from '@/components/Swiper'
import SwiperSlide from '@/components/SwiperSlide'
import TheOffersArrows from './TheOffersArrows'
import BasePromo from '@/components/BasePromo'

export default {
  name: 'TheOffersImages',
  components: {
    Swiper,
    SwiperSlide,
    TheOffersArrows,
    BasePromo,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiper: null,
      swiperOptions: {},
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isMobile']),
    maxIndex() {
      return this.items.length - 1
    },
  },
  watch: {
    index() {
      if (this.swiper) {
        this.swiper.slideTo(this.index)
      }
    },
  },
  mounted() {
    this.swiperOptions = Object.assign({}, this.options, this.swiperOptions)
    this.swiperOptions.pagination = {
      el: this.$refs.pagination,
      clickable: true,
    }
    this.$refs.swiperComponent.reset()
  },
  methods: {
    handleSwiperReady() {
      this.$emit('change', 0)
      this.swiper = this.$refs.swiperComponent.swiper
    },
    handleSlideChange() {
      this.$emit('change', this.swiper.activeIndex)
    },
    handleArrowsChange(index) {
      this.$emit('change', index)
    },
  },
}
</script>

<style lang="scss">
.TheOffersImages {
  @include aspect-ratio(570px, 760px);
  @include sm {
    width: convertify(12);
    margin-bottom: $spacer/2;
  }
  @include md {
    width: convertify(10);
    margin-bottom: $spacer/2;
  }
  @include md-down {
    position: relative;
    flex: 0 1 auto;
    z-index: 2;
  }
  @include lg-up {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
    width: convertify(6.3);
    transform: translateX(-50%) translateY(-50%);
  }

  &__Item {
    position: relative;

    .BasePromo {
      @include sm {
        top: 30%;
        right: 30%;
      }
      @include md {
        top: 30%;
        right: 30%;
      }
      @include lg-up {
        top: 30%;
        right: 30%;
      }
      position: absolute;
    }

    > img {
      width: 100%;
    }
  }

  // Swiper Slide

  .swiper-slide {
    padding-bottom: 30px;
  }

  // Swiper Pagination

  .swiper-pagination {
    @include md-down {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      bottom: 0;
    }
    @include lg-up {
      display: none;
    }
  }

  // Swiper Pagination Bullet

  .swiper-pagination-bullet {
    @include sm {
      width: 30px;
      height: 2px;
    }
    @include md {
      width: 45px;
      height: 3px;
    }

    margin: 0 5px;
    flex: 0 1 auto;
    background-color: $color-secondary;
    border-radius: 0;
    opacity: 1;
    cursor: pointer;
    outline: none;

    &-active {
      background-color: $color-primary;
    }
  }
}
</style>
