<template>
  <section class="TheNewsletter">
    <div class="TheNewsletter__Inner">
      <GridRow class="TheNewsletter__Row">
        <BaseHeading
          class="TheNewsletter__Heading"
          :suptitle="suptitle"
          :title="title"
        />
      </GridRow>
      <GridRow class="TheNewsletter__Row">
        <TheNewsletterForm class="TheNewsletter__Form" :fields="fields" />
      </GridRow>
    </div>
  </section>
</template>

<script>
import TheNewsletterForm from './components/TheNewsletterForm'
import BaseHeading from '@/components/BaseHeading'
import GridRow from '@/components/GridRow'

export default {
  name: 'TheNewsletter',
  components: {
    GridRow,
    BaseHeading,
    TheNewsletterForm,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'fields'].every((key) => key in value)
      },
    },
  },
  computed: {
    suptitle() {
      return this.content.suptitle || ''
    },
    title() {
      return this.content.title
    },
    fields() {
      return this.content.fields
    },
  },
}
</script>

<style lang="scss">
.TheNewsletter {
  @include section;
  @include segment;
  @include md-down {
    padding-left: $gutter;
    padding-right: $gutter;
  }

  position: relative;
  z-index: $index-newsletter;

  // Inner

  &__Inner {
    @include inner;
    flex-wrap: wrap;
  }

  // Row

  &__Row {
  }

  // Heading

  &__Heading {
    @include sm {
      @include grid-col(12);
    }
    @include md {
      @include grid-col(8);
      @include grid-offset-left(2);
    }
    @include lg-up {
      @include grid-col(6);
      @include grid-offset-left(3);
    }

    .BaseHeading__Suptitle {
      text-align: center;
    }

    .BaseHeading__Title {
      color: $color-tertiary;
      text-align: center;
    }
  }

  // Form

  &__Form {
    @include responsive-property(margin-top, 0, $spacer);
    @include sm {
      @include grid-col(12);
    }
    @include md {
      @include grid-col(8);
      @include grid-offset-left(2);
    }
    @include lg-up {
      @include grid-col(6);
      @include grid-offset-left(3);
    }
  }
}
</style>
