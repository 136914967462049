<template>
  <div class="TheFooterCopyright">
    <span v-text="text" />
  </div>
</template>

<script>
export default {
  name: 'TheFooterCopyright',
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.TheFooterCopyright {
  @include md-down {
    text-align: center;
  }

  font-size: 1.2rem;
}
</style>
