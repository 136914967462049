<template>
  <div class="TheOffersDetails">
    <Swiper
      ref="swiperComponent"
      v-rellax="rellaxOptions"
      :options="options"
      @ready="handleSwiperReady"
      @slide-change="handleSlideChange"
    >
      <SwiperSlide
        v-for="(item, i) in items"
        :key="`slide-${i}`"
        class="TheOffersDetails__Item"
      >
        <TheOffersDetailsItem
          :type="item.type || ''"
          :name="item.name"
          :details="item.details"
          :prices="item.prices"
        />
      </SwiperSlide>
    </Swiper>
    <div v-if="isMobile" class="TheOffersDetails__Background"></div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import Swiper from '@/components/Swiper'
import SwiperSlide from '@/components/SwiperSlide'
import TheOffersDetailsItem from './TheOffersDetailsItem'

export default {
  name: 'TheOffersDetails',
  components: {
    Swiper,
    SwiperSlide,
    TheOffersDetailsItem,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    options: {
      type: Object,
      default: () => {
        return {}
      },
    },
  },
  data() {
    return {
      swiper: null,
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isMobile', 'isDesktop']),
    rellaxOptions() {
      return this.isDesktop ? { speed: 1, center: true } : false
    },
  },
  watch: {
    index() {
      if (this.swiper) {
        this.swiper.slideTo(this.index)
      }
    },
  },
  methods: {
    handleSwiperReady() {
      this.$emit('change', 0)
      this.swiper = this.$refs.swiperComponent.swiper
    },
    handleSlideChange() {
      this.$emit('change', this.swiper.realIndex)
    },
  },
}
</script>

<style lang="scss">
.TheOffersDetails {
  @include md-down {
    flex: 0 1 auto;
    width: 100%;
    z-index: 1;
  }
  @include lg-up {
    height: 630px;
    width: convertify(4, 6);
    margin-left: convertify(2, 6);
    z-index: 2;
  }

  position: relative;

  // Background

  &__Background {
    @include sm {
      @include size(calc(100% + (#{$gutter} * 2)), calc(100% + 130px));
      margin-left: -$gutter;
      margin-right: -$gutter;
    }
    @include md {
      @include size(calc(100% + (#{$gutter} * 2)), calc(100% + 150px));
      margin-left: -$gutter;
      margin-right: -$gutter;
    }
    @include md-down {
      position: absolute;
      bottom: 0;
      left: 0;
      z-index: 0;
      background-color: $color-foreground;
    }
  }

  // Swiper Container

  .swiper-container {
    @include lg-up {
      height: 100%;
    }
  }

  // Swiper Wrapper

  .swiper-wrapper {
    @include lg-up {
      height: 100% !important;
    }
  }

  // Swiper Slide

  .swiper-slide {
  }
}
</style>
