<template>
  <div class="TheAboutImages">
    <BaseImage
      v-if="firstImage"
      v-rellax="rellaxOptions1"
      class="TheAboutImages__Item"
      :src="firstImage.src"
      :alt="firstImage.alt"
    />
    <BaseImage
      v-if="secondImage"
      v-rellax="rellaxOptions2"
      class="TheAboutImages__Item"
      :src="secondImage.src"
      :alt="secondImage.alt"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseImage from '@/components/BaseImage'

export default {
  name: 'TheAboutImages',
  components: {
    BaseImage,
  },
  props: {
    images: {
      type: Array,
      required: true,
      validator: (value) => {
        return value.length > 0
      },
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    firstImage() {
      return this.images[0] || null
    },
    secondImage() {
      return this.images[1] || null
    },
    rellaxOptions1() {
      return this.isDesktop ? { speed: -2, center: true } : false
    },
    rellaxOptions2() {
      return this.isDesktop ? { speed: 2, center: true } : false
    },
  },
}
</script>

<style lang="scss">
.TheAboutImages {
  @include md-down {
    @include grid-col(12);
  }
  @include lg-up {
    @include grid-col(6);
    position: relative;
  }

  padding-bottom: $spacer;

  // Item

  &__Item {
    &:first-of-type {
      @include md-down {
        @include responsive-property(height, 200px, 350px);
        width: 100%;
      }
      @include lg-up {
        width: convertify(5, 6);
      }
    }

    &:last-of-type {
      @include md-down {
        display: none;
      }
      @include lg-up {
        position: absolute;
        right: 0;
        bottom: -80px;
        width: convertify(4, 6);
        height: 265px;
        display: block;
      }
    }
  }
}
</style>
