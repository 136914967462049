<template>
  <span class="FormError" v-html="errorMessage"></span>
</template>

<script>
export default {
  name: 'FormError',
  props: {
    errorsBag: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    errorMessage() {
      return this.errorsBag[0] || ''
    },
  },
}
</script>

<style lang="scss">
.FormError {
  display: block;
  margin-top: 3px;
  margin-bottom: 3px;
  padding: 6px 0;
  font-size: 1.1rem;
  color: $color-alert;
}
</style>
