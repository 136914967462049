<template>
  <div class="Foundation">
    <TheFoundation :text="text" :pillars="pillars" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheFoundation from '@/components/TheFoundation'
import { Views } from '@/mixins'

export default {
  name: 'Foundation',
  components: {
    TheFoundation,
  },
  mixins: [Views],
  computed: {
    ...mapGetters('api', {
      content: 'getContent',
    }),
    text() {
      return this.content.about.foundation.text
    },
    pillars() {
      return this.content.about.foundation.pillars
    },
  },
}
</script>
