<template>
  <div class="TheShopListItem">
    <div class="TheShopListItem__Inner">
      <BaseIcon icon="map-pin" />
      <div>
        <div class="TheShopListItem__Head" @click="handleClick">
          <div>
            <h3 class="TheShopListItem__Title" v-text="name" />
            <p class="TheShopListItem__Address" v-text="address" />
          </div>
          <BaseIcon v-if="hasDetails" icon="chevron-down" />
        </div>
        <SlideUpDown v-if="hasDetails" :active="active">
          <div class="TheShopListItem__Body">
            <p
              v-if="description"
              class="TheShopListItem__Description"
              v-html="description"
            />
            <ul v-if="hasDetailsList" class="TheShopListItem__Contact">
              <li v-if="phone">
                <BaseIcon icon="phone" />
                <a href="`tel:${phone}`" v-text="phone" />
              </li>
              <li v-if="email">
                <BaseIcon icon="email" />
                <a
                  href="#"
                  @click.prevent.stop="handleEmailClick"
                  v-text="contactText"
                />
              </li>
            </ul>
          </div>
        </SlideUpDown>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations, mapActions } from 'vuex'
import BaseIcon from '@/components/BaseIcon'
import SlideUpDown from '@/components/SlideUpDown'

export default {
  name: 'TheShopListItem',
  components: {
    BaseIcon,
    SlideUpDown,
  },
  props: {
    index: {
      type: Number,
      required: true,
    },
    active: {
      type: Boolean,
      required: true,
    },
    coordinates: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    address: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    phone: {
      type: String,
      default: '',
    },
    email: {
      type: String,
      default: '',
    },
    contact: {
      type: String,
      default: '',
    },
  },
  computed: {
    ...mapState('page', ['showContactForm']),
    hasDetails() {
      return this.description || this.phone || this.email
    },
    hasDetailsList() {
      return this.phone || this.email
    },
    contactText() {
      return this.contact || 'Contacter la cave'
    },
  },
  methods: {
    ...mapMutations('page', ['setSelectedShop']),
    ...mapActions('page', ['scrollToContactForm']),
    handleClick() {
      this.$emit('click', this.index)
    },
    handleEmailClick() {
      this.setSelectedShop(this.email)
      this.scrollToContactForm()
    },
  },
}
</script>

<style lang="scss">
.TheShopListItem {
  @include responsive-property(padding-left, $spacer * 0.75, $spacer);
  @include responsive-property(padding-right, $spacer * 0.75, $spacer);

  color: white;

  &:not(:last-of-type) {
    .TheShopListItem__Inner {
      border-bottom: 1px solid rgba(white, 0.2);
    }
  }

  a {
    @include transition(opacity);

    @include hover {
      opacity: 0.7;
    }

    color: white;
    will-change: opacity;
  }

  // Inner

  &__Inner {
    @include responsive-property(padding-top, $spacer * 0.75, $spacer);
    @include responsive-property(padding-bottom, $spacer * 0.75, $spacer);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    > div {
      flex: 1 1 auto;
    }

    > .BaseIcon {
      font-size: 3rem;
      flex: 0 0 auto;
      margin-right: $spacer;
    }
  }

  // Head

  &__Head {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    cursor: pointer;
    .BaseIcon {
      font-size: 2.4rem;
    }
  }

  // Body

  &__Body {
    @include responsive-property(font-size, 14px, 16px);
    padding-top: 20px;
  }

  // Title

  &__Title {
    @include responsive-property(font-size, 18px, 20px);
    font-family: $font-family;
    font-weight: 600;
    letter-spacing: 1.5px;
  }

  // Address

  &__Address {
    @include responsive-property(font-size, 15px, 16px);
    opacity: 0.6;
  }

  // Description

  &__Description {
    padding-bottom: 20px;
  }

  // Contact

  &__Contact {
    li {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;

      &:not(:last-of-type) {
        margin-bottom: 10px;
      }
    }

    .BaseIcon {
      color: white;
      font-size: 2rem;
      margin-right: 10px;
    }
  }
}
</style>
