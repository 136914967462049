<template>
  <div :class="classNames">
    <SlideUpDown :active="visible">
      <div class="TheHeaderMenu__Inner">
        <nav v-scroll-lock="isMobile && visible" class="TheHeaderMenu__Nav">
          <ul>
            <li v-for="item in items" :key="item.target">
              <a href="#" @click.prevent.stop="handleClick(item.target)">
                <span>{{ item.text }}</span>
              </a>
            </li>
          </ul>
        </nav>
        <a
          v-if="cta.text"
          class="TheHeaderMenu__Cta"
          :href="cta.url"
          target="_blank"
        >
          <span v-text="cta.text" />
        </a>
      </div>
    </SlideUpDown>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'
import SlideUpDown from '@/components/SlideUpDown'

export default {
  name: 'TheHeaderMenu',
  components: {
    SlideUpDown,
  },
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
    cta: {
      type: Object,
      default: () => ({
        text: '',
        url: '',
      }),
    },
    visible: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapGetters('breakpoints', ['isMobile']),
    classNames() {
      return ['TheHeaderMenu']
    },
  },
  methods: {
    ...mapMutations('page', ['setShowContactForm']),
    handleClick(target) {
      this.$emit('select')
      this.$scrollTo(`#${target}`, {
        offset: target === 'contact' ? -200 : 0,
        onDone: () => {
          if (target === 'contact') {
            this.setShowContactForm(true)
          }
        },
      })
    },
  },
}
</script>

<style lang="scss">
.TheHeaderMenu {
  @include md-down {
    position: fixed;
    width: 100%;
    left: 0;
    top: 0;
    overflow-y: hidden;
    background-color: $color-background;
    // height: 0;
    transition-property: height, padding;
    transition-duration: $transition-duration;
    transition-timing-function: $transition-easing;
    will-change: height, padding;
  }
  @include lg-up {
    margin-left: 30px;
  }

  &.is-visible {
    @include responsive-property(padding, $spacer/2, $spacer);
    @include md-down {
      // height: 100vh;
    }
    @include lg-up {
      padding: 0 !important;
    }
  }

  // Inner

  &__Inner {
    @include md-down {
      @include responsive-property(padding-top, 140px, 190px);
      @include responsive-property(border-width, $gutter/2, $gutter);
      height: 100vh;
      border-style: solid;
      border-color: white;
      background-color: $color-tertiary;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
      overflow-y: auto;
    }
    @include lg-up {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }
  }
  // Cta

  &__Cta {
    @include transition(background-color);
    @include md-down {
      width: 85%;
      margin-top: 30px;
      margin-bottom: 30px;
      padding: 20px 15px !important;
    }
    @include lg-up {
      margin-left: 15px;
      padding: 10px 15px;
    }
    flex: 0 1 auto;

    background-color: $color-primary;
    will-change: background-color;
    cursor: pointer;

    @include hover {
      background-color: tint($color-primary, 10%);
    }

    &::after {
      display: none !important;
    }
  }

  // Nav

  &__Nav {
    @include md-down {
      // height: 100%;
      width: 100%;
      flex: 0 1 auto;
    }
    @include lg-up {
      flex: 0 0 auto;
    }
  }

  // List

  ul {
    @include md-down {
      overflow-y: auto;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }
    @include lg-up {
      flex-direction: row;
      align-items: center;
    }

    display: flex;
    justify-content: flex-start;
  }

  // List Item

  li {
    @include lg-up {
      padding: $spacer/3;
      width: auto;
    }
    @include xl-up {
      padding: $spacer/2;
    }

    flex: 0 1 auto;
    width: 85%;
  }

  // Anchors

  a {
    @include md-down {
      padding: $spacer;
      border-top-width: 1px;
      border-style: solid;
      border-color: rgba(white, 0.2);
    }
    @include lg-up {
      @include set-underline-hover;
      @include responsive-property(font-size, 13px, 16px, $min-1080, $max-1280);
    }

    display: block;

    color: white;
    font-size: 1.6rem;
    text-align: center;
  }
}
</style>
