<template>
  <div class="TheHistory">
    <GridRow>
      <div class="TheHistory__Col">
        <div class="TheHistory__Image">
          <BaseImage :src="image.src" :alt="image.alt" />
        </div>
      </div>
      <div class="TheHistory__Col">
        <div class="TheHistory__Text">
          <BaseText :text="text" />
        </div>
      </div>
    </GridRow>
  </div>
</template>

<script>
import BaseImage from '@/components/BaseImage'
import BaseText from '@/components/BaseText'
import GridRow from '@/components/GridRow'

export default {
  name: 'TheHistory',
  components: {
    BaseImage,
    BaseText,
    GridRow,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    image: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['src', 'alt'].every((key) => key in value)
      },
    },
  },
}
</script>

<style lang="scss">
.TheHistory {
  @include lg-up {
    padding-top: $spacer * 2;
    padding-bottom: $spacer * 2;
  }
  width: 100%;
  height: 100%;
  box-sizing: border-box;

  .GridRow {
    height: 100%;
  }

  // Col

  &__Col {
    @include md-down {
      @include grid-col(12);
    }

    @include lg-up {
      @include grid-col(6);
    }
  }

  // Image

  &__Image {
    @include responsive-property(
      padding-left,
      $spacer,
      $spacer * 2,
      $min-1080,
      $max-1280
    );
    @include responsive-property(
      padding-right,
      $spacer,
      $spacer * 2,
      $min-1080,
      $max-1280
    );

    @include md-down {
      padding: 0 0;
    }

    height: 100%;
    box-sizing: border-box;

    .BaseImage {
      @include box-shadow;
      @include sm {
        min-height: 450px;
      }
      @include md {
        min-height: 650px;
      }
      @include lg-up {
        min-height: 450px;
      }
    }
  }

  // Text

  &__Text {
    @include md-down {
      padding: $spacer;
    }
    @include lg-up {
      padding: 0 $spacer * 2 0 0;
    }

    width: 100%;
    box-sizing: border-box;

    .BaseText {
      color: $color-secondary;
    }
  }
}
</style>
