<template>
  <div class="TheFoundation">
    <GridRow v-if="text"></GridRow>
    <GridRow>
      <TheFoundationText :text="text" />
      <TheFoundationPillar
        v-for="(pillar, index) in pillars"
        :key="pillar.name"
        :index="index + 1"
        :content="pillar"
        :illu="illus[index]"
      />
    </GridRow>
  </div>
</template>

<script>
import GridRow from '@/components/GridRow'
import TheFoundationText from './components/TheFoundationText'
import TheFoundationPillar from './components/TheFoundationPillar'
import Pillar1 from '@/assets/images/foundation_pillar_1.svg'
import Pillar2 from '@/assets/images/foundation_pillar_2.svg'
import Pillar3 from '@/assets/images/foundation_pillar_3.svg'

export default {
  name: 'TheFoundation',
  components: {
    GridRow,
    TheFoundationText,
    TheFoundationPillar,
  },
  props: {
    pillars: {
      type: Array,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    illus() {
      return [Pillar1, Pillar2, Pillar3]
    },
  },
}
</script>

<style lang="scss">
.TheFoundation {
  @include lg-up {
    padding: $spacer * 2;
    padding-bottom: 0;
  }

  width: 100%;
  height: 100%;
  box-sizing: border-box;
}
</style>
