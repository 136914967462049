<template>
  <ValidationProvider
    v-slot="{ errors, invalid }"
    slim
    :rules="rules"
    :name="label"
  >
    <div :class="[classNames, { 'is-invalid': invalid }]">
      <label class="FormCheckbox__Label" :for="name">
        <span v-text="label"></span>
        <input
          :id="name"
          v-model="fieldValue"
          class="FormCheckbox__Field"
          type="checkbox"
        />
      </label>
      <FormError class="FormCheckbox__Error" :errors-bag="errors" />
    </div>
  </ValidationProvider>
</template>

<script>
import FormError from '@/components/FormError'
import { ValidationProvider } from 'vee-validate'
// import { required } from 'vee-validate/dist/rules'

export default {
  name: 'FormCheckbox',
  components: {
    FormError,
    ValidationProvider,
  },
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    value: {
      type: Boolean,
      required: true,
    },
    rules: {
      type: [String, Object],
      required: true,
    },
    theme: {
      type: String,
      default: 'contact',
    },
  },
  data() {
    return {
      fieldValue: '',
    }
  },
  computed: {
    classNames() {
      return [
        'FormCheckbox',
        `FormCheckbox--${this.theme}`,
        { 'is-selected': this.fieldValue },
      ]
    },
  },
  watch: {
    value() {
      this.fieldValue = this.value
    },
    fieldValue(val) {
      this.$emit('input', val)
    },
  },
  created() {
    this.fieldValue = this.value
  },
}
</script>

<style lang="scss">
.FormCheckbox {
  margin-bottom: 5px;

  &.is-selected {
    .FormCheckbox__Label::after {
      background-color: rgba(white, 1);
    }
  }

  // Label

  &__Label {
    position: relative;
    display: block;
    cursor: pointer;

    > span {
      display: block;
      padding-left: $spacer;
      font-weight: 600;
      font-size: 1.3rem;
    }

    &::before,
    &::after {
      @include size(16px);
      position: absolute;
      z-index: 1;

      display: block;

      content: '';
    }

    &::before {
      top: 2px;
      left: 0;

      border-width: 1px;
      border-style: solid;
      border-color: white;
      border-radius: 5px;
    }

    &::after {
      @include size(10px);
      @include transition(background-color);
      top: 5px;
      left: 3px;

      border-radius: 3px;
      background-color: rgba(white, 0);

      will-change: background-color;
    }
  }

  // Field

  &__Field {
    @include hide-visually;
    outline: none;
  }

  // Themes

  // Themes | Contact

  &--contact {
    font-size: 1.4rem;
    font-weight: 600;
    color: rgba(white, 0.4);

    .FormCheckbox__Field {
      border-radius: 5px;
      color: white;
      background-color: rgba(white, 0.2);
    }

    .FormCheckbox__Label {
      color: white;
    }
  }

  // Themes | Newsletter

  &--newsletter {
    .FormCheckbox__Field {
      font-size: 1.8rem;
      font-weight: 300;
      color: rgba($color-secondary, 0.4);
    }
  }
}
</style>
