<template>
  <div class="History">
    <TheHistory :text="text" :image="image" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import TheHistory from '@/components/TheHistory'
import { Views } from '@/mixins'

export default {
  name: 'History',
  components: {
    TheHistory,
  },
  mixins: [Views],
  computed: {
    ...mapGetters('api', {
      content: 'getContent',
    }),
    text() {
      return this.content.about.history.text
    },
    image() {
      return this.content.about.history.image
    },
  },
}
</script>

<style lang="scss">
.History {
  height: 100%;
}
</style>
