<template>
  <nav class="TheOffersNav">
    <ul>
      <li v-for="(item, i) in items" :key="item">
        <a
          href="#"
          :class="getActiveClass(i)"
          @click.prevent.stop="handleClick(i)"
        >
          <span>{{ item }}</span>
        </a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'TheOffersNav',
  props: {
    index: {
      type: Number,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
  },
  methods: {
    handleClick(index) {
      this.$emit('change', index)
    },
    getActiveClass(index) {
      return { 'is-active': index === this.index }
    },
  },
}
</script>

<style lang="scss">
.TheOffersNav {
  flex: 0 1 auto;
  margin-bottom: $spacer * 2;

  li {
    margin-bottom: $spacer;
  }

  a {
    @include transition(color);
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 3.2rem;
    font-weight: 600;
    font-family: $font-heading;
    line-height: 1;
    color: rgba($color-secondary, 0.2);
    will-change: color;

    &::before {
      @include transition(background-color);
      content: '';
      display: block;
      height: 2px;
      width: 40px;
      margin-right: 15px;
      background-color: rgba($color-secondary, 0.2);
      will-change: background-color;
    }

    &:hover,
    &:focus {
      color: shade($color-foreground, 10%);

      &::before {
        background-color: $color-foreground;
      }
    }

    &.is-active {
      color: $color-primary;
      &::before {
        background-color: $color-primary;
      }
    }
  }

  span {
  }
}
</style>
