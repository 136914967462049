<template>
  <header :class="mainClasses">
    <div class="TheHeader__Wrapper">
      <TheHeaderLogo :text="content.logo" />
      <TheHeaderHamburger
        v-show="isMobile"
        :active="toggleMenu"
        @click="handleClick"
      />
      <TheHeaderMenu
        :items="content.items"
        :cta="content.cta"
        :visible="showMenu"
        @select="handleMenuSelection"
      />
    </div>
  </header>
</template>

<script>
import { mapGetters } from 'vuex'
import TheHeaderHamburger from './components/TheHeaderHamburger'
import TheHeaderLogo from './components/TheHeaderLogo'
import TheHeaderMenu from './components/TheHeaderMenu'

export default {
  name: 'TheHeader',
  components: {
    TheHeaderLogo,
    TheHeaderHamburger,
    TheHeaderMenu,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['logo', 'items'].every((key) => key in value)
      },
    },
  },
  data() {
    return {
      toggleMenu: false,
    }
  },
  computed: {
    mainClasses() {
      return ['TheHeader']
    },
    ...mapGetters('breakpoints', ['isDesktop', 'isMobile']),
    showMenu() {
      return this.isDesktop || (this.isMobile && this.toggleMenu)
    },
  },
  methods: {
    handleMenuSelection() {
      this.toggleMenu = !this.toggleMenu
    },
    handleClick() {
      this.$scrollTo('#app', {
        duration: 300,
        onDone: () => {
          this.toggleMenu = !this.toggleMenu
        },
      })
    },
  },
}
</script>

<style lang="scss">
.TheHeader {
  @include section;
  @include responsive-property(top, 30px, 80px);

  position: absolute;
  left: 0;
  z-index: 100;

  width: 100%;

  // Wrapper

  &__Wrapper {
    @include responsive-property(padding-left, $gutter, $gutter * 2);
    @include responsive-property(padding-right, $gutter, $gutter * 2);

    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  // Hamburger

  .TheHeaderHamburger {
    @include md-down {
      position: relative;
      z-index: 1;
    }
  }

  // Logo

  .TheHeaderLogo {
    @include md-down {
      position: relative;
      z-index: 1;
    }
  }

  // Menu

  .TheHeaderMenu {
    @include md-down {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 0;
    }

    flex: 0 0 auto;
  }
}
</style>
