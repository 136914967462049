import { configure, extend, localize, setInteractionMode } from 'vee-validate'
import { required, email } from 'vee-validate/dist/rules'
import fr from 'vee-validate/dist/locale/fr.json'

// Global Config
configure({
  useConstraintAttrs: false,
})

// Change Ux Interaction mode
setInteractionMode('passive')

// Install rules
extend('required', required)
extend('email', email)

// Install messages
localize('fr', fr)
