<template>
  <div class="TheLegal">
    <BaseHeading :title="title" />
    <BaseText :text="text" />
  </div>
</template>

<script>
import BaseText from '@/components/BaseText'
import BaseHeading from '@/components/BaseHeading'

export default {
  name: 'TheLegal',
  components: {
    BaseText,
    BaseHeading,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.TheLegal {
  @include responsive-property(
    padding,
    $spacer,
    $spacer * 2,
    $min-1080,
    $max-1280
  );

  width: 100%;
  box-sizing: border-box;
}
</style>
