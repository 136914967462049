<template>
  <div v-show="active" :class="classNames" :style="mainStyles">
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn animation-delay-100"
      leave-active-class="animated fadeOut animation-delay-600"
    >
      <div
        v-show="animate"
        class="BaseModal__Overlay"
        @click="handleClose"
      ></div>
    </transition>

    <div class="BaseModal__Inner">
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeInDown animation-delay-500"
        leave-active-class="animated fadeOutUp animation-delay-500"
      >
        <div v-show="animate" class="BaseModal__Content" :style="contentStyles">
          <slot></slot>
        </div>
      </transition>
      <transition
        name="custom-classes-transition"
        enter-active-class="animated fadeIn animation-delay-2000"
        leave-active-class="animated fadeOut"
      >
        <BaseClose v-show="animate" @click.native="handleClose" />
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import { wait } from '@/helpers/time'
import BaseClose from '@/components/BaseClose'

export default {
  name: 'BaseModal',
  components: {
    BaseClose,
  },
  props: {
    active: {
      type: Boolean,
      required: true,
    },
  },
  data() {
    return {
      animate: false,
    }
  },
  computed: {
    ...mapState('breakpoints', ['height']),
    ...mapGetters('breakpoints', ['isMobile']),
    classNames() {
      return ['BaseModal']
    },
    mainStyles() {
      return `height: ${this.height}px;`
    },
    contentStyles() {
      return this.isMobile
        ? `height: ${this.height - 140}px;`
        : `height: ${this.height - 200}px;`
    },
  },
  watch: {
    active() {
      if (this.active) this.handleOpen()
    },
  },
  methods: {
    ...mapActions('page', ['updatePreventBodyScroll']),
    handleOpen() {
      this.animate = true
    },
    handleClose() {
      this.animate = false
      wait(1200, () => {
        this.$emit('close')
      })
    },
  },
}
</script>

<style lang="scss">
.BaseModal {
  @include fit(fixed);
  @include section;
  @include center-flex;
  @include md-down {
    padding: 70px 0;
  }
  @include lg-up {
    padding: 100px 0;
  }

  align-items: center;
  z-index: $index-modal;

  // Overlay

  &__Overlay {
    @include fit(fixed);
    background-color: rgba($color-secondary, 0.9);
    z-index: 0;
    cursor: pointer;
  }

  // Inner

  &__Inner {
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: $page-width;
  }

  // Content

  &__Content {
    @include md-down {
      height: calc(100vh - 140px);
    }
    @include lg-up {
      height: calc(100vh - 200px);
      max-height: 720px;
    }

    width: 100%;
    background-color: white;
    overflow-y: hidden;
  }

  // Close

  .BaseClose {
    @include responsive-property(top, -10px, -$spacer * 0.75);
    @include md-down {
      left: 50%;
      transform: translateX(-50%) translateY(-100%);
    }
    @include lg-up {
      right: 0;
      transform: translateY(-100%);

      @include hover {
        transform: translateY(-100%) rotate(180deg);
      }
    }

    position: absolute;
  }
}
</style>
