import { wait } from '@/helpers/time'
import scrollMonitor from 'scrollmonitor'

export default {
  name: 'TransitionReveal',
  props: {
    classIn: {
      type: String,
      default: 'fadeIn',
    },
    classOut: {
      type: String,
      default: 'fadeOut',
    },
    offset: {
      type: Number,
      default: 0,
    },
    delay: {
      type: Number,
      default: 600,
    },
    once: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      state: false, // default, hidden, visible
      watcher: undefined,
      duration: 700,
      childElement: undefined,
      childComponent: undefined,
    }
  },
  computed: {
    customStyles() {
      return `opacity: ${this.styles.opacity}; visibility: ${this.styles.visibility};`
    },
  },
  mounted() {
    if (this.$el) this.initialize()
  },
  methods: {
    initialize() {
      this.childElement = this.$el
      this.childComponent = this.$children[0] || null
      this.hideElement()
      this.setWatcher()
    },
    hideElement() {
      this.childElement.style.opacity = 0
      this.childElement.style.visibility = 'hidden'
    },
    animateIn() {
      wait(this.delay, () => {
        this.childElement.removeAttribute('style')
        this.childElement.classList.remove(this.classOut)
        this.childElement.classList.add('animated')
        this.childElement.classList.add(this.classIn)
      })
      wait(this.delay + this.duration, () => {
        this.childElement.classList.remove('animated')
        this.childElement.classList.remove(this.classIn)
        this.updateState(true)
      })
    },
    animateOut() {
      this.childElement.classList.remove(this.classIn)
      this.childElement.classList.add('animated')
      this.childElement.classList.add(this.classOut)

      wait(this.duration, () => {
        this.hideElement()
        this.childElement.classList.remove('animated')
        this.childElement.classList.remove(this.classOut)
        this.updateState(false)
      })
    },
    setWatcher() {
      this.watcher = scrollMonitor.create(this.childElement, this.offset)
      if (this.once) {
        this.watcher.one('enterViewport', this.onEnterViewport)
      } else {
        this.watcher.on('enterViewport', this.onEnterViewport)
        this.watcher.on('exitViewport', this.onExitViewport)
      }
    },
    onEnterViewport() {
      this.animateIn()
    },
    onExitViewport() {
      this.animateOut()
    },
    updateState(value) {
      this.state = value
      if (
        this.childComponent &&
        this.childComponent.hasOwnProperty('revealed')
      ) {
        this.childComponent.revealed = value
      }
      if (value) this.childElement.classList.add('is-revealed')
      else this.childElement.classList.remove('is-revealed')
    },
    removeListeners() {
      this.watcher.off('enterViewport', this.enterViewport)
      this.watcher.off('exitViewport', this.exitViewport)
    },
  },
  render() {
    return this.$scopedSlots.default({
      state: this.state,
    })
  },
}
