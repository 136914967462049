<template>
  <BaseModal class="TheModal" :active="open" @close="handleClose">
    <router-view />
  </BaseModal>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import BaseModal from '@/components/BaseModal'

export default {
  name: 'TheModal',
  components: {
    BaseModal,
  },
  data() {
    return {
      showModal: true,
    }
  },
  computed: {
    ...mapState('modals', ['open']),
  },
  methods: {
    ...mapMutations('modals', ['setModalOpen']),
    handleClose() {
      // this.setModalOpen(false)
      this.$router.push('/')
    },
  },
}
</script>

<style lang="scss">
.TheModal {
  width: 100%;
}
</style>
