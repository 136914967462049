<template>
  <div class="TheFoundationText">
    <BaseText :text="text" />
  </div>
</template>

<script>
import BaseText from '@/components/BaseText'
export default {
  name: 'TheFoundationText',
  components: {
    BaseText,
  },
  props: {
    text: {
      type: String,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.TheFoundationText {
  @include md-down {
    @include grid-col(12);
    padding: $spacer * 1.25;
  }
  @include lg-up {
    @include grid-col(6);
    padding-bottom: $gutter * 2;
    padding-right: $spacer * 2;
  }

  .BaseText {
    p:last-of-type {
      margin: 0;
    }
  }
}
</style>
