<template>
  <div class="TheContactTrigger">
    <div class="TheContactTrigger__Content">
      <BaseHeading :title="title" theme="contact" />
      <BaseLink :text="cta.text" theme="contact" />
    </div>
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeIn delay-animation-200"
      leave-active-class="animated fadeOut"
    >
      <div v-show="active" class="TheContactTrigger__Close">
        <BaseClose theme="light" />
      </div>
    </transition>
  </div>
</template>

<script>
import BaseClose from '@/components/BaseClose'
import BaseHeading from '@/components/BaseHeading'
import BaseLink from '@/components/BaseLink'
export default {
  name: 'TheContactTrigger',
  components: {
    BaseClose,
    BaseHeading,
    BaseLink,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    cta: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['text'].every((key) => key in value)
      },
    },
    active: {
      type: Boolean,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.TheContactTrigger {
  @include transition(background-color);
  @include md-down {
    padding-left: 30px;
    padding-right: 30px;
  }
  @include lg-up {
    padding-left: 50px;
    padding-right: 0px;
  }
  @include hover {
    .BaseLink__Icon {
      margin-left: $spacer;
    }
  }

  position: absolute;
  top: 0;
  left: 0;

  width: 100%;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  background-color: $color-primary;
  transform: translateY(-100%);
  will-change: background-color;
  cursor: pointer;

  // Content

  &__Content {
    flex: 0 1 auto;
    .BaseHeading__Title {
      @include responsive-property(font-size, 28px, 32px);
    }
  }

  // Close

  &__Close {
    flex: 0 0 auto;
    margin-top: -5px;
    margin-right: 10px;
    .BaseClose {
      transform: scale(0.9);
    }
  }
}
</style>
