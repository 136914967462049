<template>
  <section id="offers" class="TheOffers">
    <BaseForeground theme="offers" />
    <div class="TheOffers__Inner">
      <GridRow class="TheOffers__Row">
        <div v-rellax="rellaxOptions" class="TheOffers__Content">
          <div>
            <BaseHeading
              class="TheOffers__Heading"
              :suptitle="suptitle"
              :title="title"
            />
            <TheOffersNav
              v-if="isDesktop"
              :index="currentIndex"
              :items="navItems"
              @change="handleIndexChange"
            />
            <TheOffersArrows
              v-if="isDesktop"
              :index="currentIndex"
              :max="maxIndex"
              @change="handleIndexChange"
            />
          </div>
        </div>

        <div class="TheOffers__Swipers">
          <TheOffersImages
            :index="currentIndex"
            :items="imagesItems"
            :options="imagesOptions"
            @change="handleIndexChange"
          />
          <TheOffersDetails
            :index="currentIndex"
            :items="items"
            :options="detailsOptions"
            @change="handleIndexChange"
          />
          <div v-if="isDesktop" class="TheOffers__Background"></div>
        </div>
      </GridRow>
    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import BaseHeading from '@/components/BaseHeading'
import BaseForeground from '@/components/BaseForeground'
import GridRow from '@/components/GridRow'
import TheOffersDetails from './components/TheOffersDetails'
import TheOffersImages from './components/TheOffersImages'
import TheOffersNav from './components/TheOffersNav'
import TheOffersArrows from './components/TheOffersArrows'

export default {
  name: 'TheOffers',
  components: {
    BaseHeading,
    BaseForeground,
    GridRow,
    TheOffersDetails,
    TheOffersImages,
    TheOffersNav,
    TheOffersArrows,
  },
  props: {
    content: {
      type: Object,
      required: true,
      validator: (value) => {
        return ['title', 'items'].every((key) => key in value)
      },
    },
  },
  data() {
    return {
      currentIndex: 0,
      imagesOptions: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
        },
        breakpoints: {
          0: {
            allowTouchMove: true,
          },
          1080: {
            allowTouchMove: false,
          },
        },
      },
      detailsOptions: {
        effect: 'fade',
        fadeEffect: {
          crossFade: true,
        },
        keyboard: false,
        breakpoints: {
          0: {
            autoHeight: true,
            allowTouchMove: true,
          },
          1080: {
            autoHeight: false,
            allowTouchMove: false,
          },
        },
      },
    }
  },
  computed: {
    ...mapGetters('breakpoints', ['isDesktop']),
    suptitle() {
      return this.content.suptitle || ''
    },
    title() {
      return this.content.title
    },
    items() {
      return this.content.items
    },
    navItems() {
      return this.content.items.map((item) => item.name)
    },
    imagesItems() {
      return this.content.items.map((item) => {
        return {
          image: item.image,
          promo: item.promo,
        }
      })
    },
    maxIndex() {
      return this.items.length - 1
    },
    rellaxOptions() {
      return this.isDesktop ? { speed: -2, center: true } : false
    },
  },
  methods: {
    handleIndexChange(index) {
      this.currentIndex = index
    },
  },
}
</script>

<style lang="scss">
.TheOffers {
  @include segment;
  @include section;
  @include md-down {
    padding-left: $gutter;
    padding-right: $gutter;
    padding-bottom: 0;
  }
  @include lg-up {
    padding-bottom: 0;
  }
  position: relative;
  z-index: $index-offers;

  // Inner

  &__Inner {
    @include inner;

    position: relative;
    z-index: 2;
  }

  // Row

  &__Row {
    position: relative;
  }

  // Content

  &__Content {
    @include md-down {
      @include grid-col(12);
    }
    @include lg-up {
      @include grid-col(6);
      padding-top: $spacer * 2;
      padding-bottom: $spacer * 2;
    }

    position: relative;
    z-index: 2;

    > div {
      display: flex;
      justify-content: center;
      align-items: flex-start;
      flex-direction: column;
    }
  }

  // Swipers

  &__Swipers {
    @include md-down {
      @include grid-col(12);
      position: relative;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      flex-direction: column;
    }
    @include lg-up {
      @include grid-col(6);
    }
  }

  // Heading

  &__Heading {
    @include lg-up {
      margin-bottom: $spacer * 2;
    }

    flex: 0 1 auto;
  }

  // Background

  &__Background {
    @include lg-up {
      position: absolute;
      top: 0;
      left: 50%;
      z-index: 0;
      width: $page-max / 2;
      height: 100%;
      background-color: $color-foreground;
    }
  }
}
</style>
