<template>
  <div :class="mainClasses" @click.prevent.stop="handleClick">
    <span class="BaseHamburger__Bar BaseHamburger__Bar--top"></span>
    <span class="BaseHamburger__Bar BaseHamburger__Bar--middle"></span>
    <span class="BaseHamburger__Bar BaseHamburger__Bar--bottom"></span>
  </div>
</template>

<script>
export default {
  name: 'BaseHamburger',
  props: {
    active: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    mainClasses() {
      return ['BaseHamburger', { 'is-active': this.active }]
    },
  },
  methods: {
    handleClick() {
      this.$emit('click')
    },
  },
}
</script>

<style lang="scss">
.BaseHamburger {
  position: relative;
  width: 2rem;
  height: 1.4rem;
  cursor: pointer;
  margin-top: -1px;

  // Bar

  &__Bar {
    position: absolute;
    display: block;
    width: 100%;
    height: 2px;
    top: 50%;
    left: 50%;
    border-radius: 10px;
    background-color: white;
    transform: translateX(-50%) translateY(-50%);
    transition: margin 300ms $ease-in-out-sine, width 300ms $ease-in-out-sine,
      opacity 100ms $ease-in-out-sine, transform 300ms $ease-in-out-sine;

    &--top {
      margin-top: -6px;
    }
    &--bottom {
      margin-top: 6px;
    }
  }

  // Active

  &.is-active {
    .BaseHamburger__Bar--top {
      transform: translateX(-50%) rotate(45deg);
      margin-top: -1px;
    }
    .BaseHamburger__Bar--middle {
      transform: translateX(100%) translateY(-50%);
      opacity: 0;
    }
    .BaseHamburger__Bar--bottom {
      width: 100%;
      margin-top: -1px;
      transform: translateX(-50%) rotate(-45deg);
    }
  }
}
</style>
