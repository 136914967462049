<template>
  <nav class="TheOffersArrows">
    <button :class="prevClasses" @click.prevent="handlePrevClick">
      <BaseIcon icon="arrow" />
    </button>
    <button :class="nextClasses" @click.prevent="handleNextClick">
      <BaseIcon icon="arrow" />
    </button>
  </nav>
</template>

<script>
import BaseIcon from '@/components/BaseIcon'

export default {
  name: 'TheOffersArrows',
  components: {
    BaseIcon,
  },
  props: {
    max: {
      type: Number,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
  },
  computed: {
    prevClasses() {
      return ['TheOffersArrows__Prev', { 'is-disabled': this.index === 0 }]
    },
    nextClasses() {
      return [
        'TheOffersArrows__Next',
        { 'is-disabled': this.index === this.max },
      ]
    },
  },
  methods: {
    handlePrevClick() {
      this.$emit('change', this.index - 1)
    },
    handleNextClick() {
      this.$emit('change', this.index + 1)
    },
  },
}
</script>

<style lang="scss">
.TheOffersArrows {
  @include md-down {
    position: absolute;
    top: 50%;
    left: 0;
    z-index: 2;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    pointer-events: none;
    transform: translateY(-50%);
  }

  button {
    @include size(50px, 100px);
    @include md-down {
      flex: 0 1 auto;
      pointer-events: initial;
    }

    font-size: 2rem;

    &.is-disabled {
      opacity: 0.25;
      cursor: default;
    }
  }

  // Prev

  &__Prev {
    .BaseIcon {
      transform: rotate(180deg);
    }
  }

  // Next

  &__Next {
    @include lg-up {
      margin-left: 10px;
    }
  }
}
</style>
