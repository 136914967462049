<template>
  <div class="FiltersList">
    <ul>
      <li v-for="filter in filters" :key="filter.target">
        <a
          href="#"
          :class="{ 'is-active': current === filter.category }"
          @click.prevent.stop="handleClick(filter.category)"
          v-text="filter.text"
        />
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'FiltersList',
  props: {
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      current: 'all',
    }
  },
  methods: {
    handleClick(category) {
      this.current = category
      this.$emit('update', category)
    },
  },
}
</script>

<style lang="scss">
.FiltersList {
  ul {
    @include sm {
      width: 100%;
      justify-content: flex-start;
    }
    @include md {
      width: convertify(6);
      margin-left: auto;
      justify-content: flex-end;
    }
    @include lg-up {
      justify-content: flex-start;
    }

    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }

  li {
    @include sm {
      margin-bottom: 10px;
      &:not(:last-of-type) {
        margin-right: $spacer/2;
      }
    }
    @include md {
      &:not(:last-of-type) {
        margin-right: $spacer;
      }
    }
    @include lg-up {
      &:not(:last-of-type) {
        margin-right: $spacer;
      }
    }

    flex: 0 1 auto;
  }

  a {
    @include set-underline-hover(left, rgba($color-secondary, 0.4));
    @include responsive-property(font-size, 17px, 18px);
    @include transition(color);

    display: block;
    color: rgba($color-secondary, 0.2);
    will-change: color;

    &:hover,
    &:focus {
      color: rgba($color-secondary, 0.4);
    }

    &.is-active {
      @include set-underline($color-primary);
      color: $color-primary;
    }
  }
}
</style>
