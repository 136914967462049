import VueScrollTo from 'vue-scrollto'
import { wait } from '@/helpers/time'

const state = {
  preventBodyScroll: false,
  scrollPosition: 0,
  scrollDirection: 'down',
  showContactForm: false,
  selectedShop: '',
}

const mutations = {
  setPreventBodyScroll: (state, boolean) => {
    state.preventBodyScroll = boolean
  },
  setScrollPosition: (state, number) => {
    state.scrollPosition = number
  },
  setScrollDirection: (state, string) => {
    state.scrollDirection = string
  },
  setSelectedShop: (state, string) => {
    state.selectedShop = string
  },
  setShowContactForm: (state, boolean) => {
    console.log(boolean)
    state.showContactForm = boolean
  },
}

const actions = {
  updatePreventBodyScroll: ({ commit, dispatch }, boolean) => {
    commit('setPreventBodyScroll', boolean)
    const className = 'no-scroll'
    if (boolean) document.body.classList.add(className)
    else document.body.classList.remove(className)
  },
  scrollToContactForm: (store) => {
    VueScrollTo.scrollTo('#TheContactTrigger', {
      duration: 500,
      offset: -200,
    })
    wait(200, () => {
      store.commit('setShowContactForm', true)
    })
  },
}

export default {
  state,
  actions,
  mutations,
  namespaced: true,
}
