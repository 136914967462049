<template>
  <nav class="AboutNavigation">
    <ul>
      <li v-for="item in items" :key="item.name">
        <router-link
          :class="{ 'is-active': $route.name === item.name }"
          :to="{ name: item.name }"
          v-text="item.title"
        />
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  name: 'AboutNavigation',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
}
</script>

<style lang="scss">
.AboutNavigation {
  ul {
    @include md-down {
      flex-direction: column;
      align-items: flex-start;
    }
    @include lg-up {
      align-items: stretch;
      flex-direction: row;
    }
    display: flex;
    justify-content: flex-start;
  }

  li {
    @include md-down {
      width: 100%;
      flex: 1 1 auto;
    }

    @include lg-up {
      width: 50%;
      flex: 0 1 auto;
    }
  }

  a {
    @include transition;
    @include md-down {
      padding: $spacer * 0.7;
      line-height: 1;
    }
    @include lg-up {
      padding: $spacer;
      line-height: 1.15;
    }
    display: block;

    color: $color-secondary;
    background-color: white;
    border-bottom: 1px solid shade(white, 20%);
    text-align: center;
    font-size: 1.7rem;

    will-change: color, background-color, border-bottom;

    &:hover,
    &:focus {
      color: $color-primary;
      background-color: $color-foreground;
      border-bottom: 1px solid $color-primary;
    }

    &.is-active {
      color: white;
      background-color: $color-primary;
      border-bottom: 1px solid $color-primary;
    }
  }
}
</style>
