import DataLayerPushes from './DataLayerPushes'

export default {
  version: '1.0.0',
  install(Vue, options = {}) {
    if (this.installed) {
      return
    }
    this.installed = true
    this.params = options
    Vue.prototype.$gtm = DataLayerPushes
    Vue['$gtm'] = DataLayerPushes
  },
}
