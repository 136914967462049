<template>
  <div class="TheFooterMenu">
    <nav>
      <ul>
        <li v-for="item in items" :key="item.target">
          <a
            v-if="item.url !== '#'"
            :href="item.url"
            target="_blank"
            v-text="item.text"
          />
          <router-link v-else :to="{ name: 'legal' }" v-text="item.text" />
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: 'TheFooterMenu',
  props: {
    items: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  methods: {
    handleClick() {},
  },
}
</script>

<style lang="scss">
.TheFooterMenu {
  flex: 0 0 auto;

  nav {
  }

  ul {
    @include md-down {
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    @include lg-up {
      justify-content: flex-start;
      align-items: center;
    }

    display: flex;
  }

  li {
    @include md-down {
      padding-top: $spacer/2;
      padding-bottom: $spacer/2;
    }
    @include lg-up {
      padding-left: $spacer/2;
      padding-right: $spacer/2;
    }
  }

  a {
    @include responsive-property(font-size, 13px, 14px);
    @include lg-up {
      @include set-underline-hover(left, $color-secondary);
    }

    display: block;
    color: $color-secondary;
  }
}
</style>
