<template>
  <div id="app" class="App">
    <div v-if="successStatus" class="App__Layout">
      <router-view />
    </div>
    <div v-else class="App__Spinner-Wrapper">
      <BaseSpinner />
    </div>
    <notifications classes="notification" position="top left" />
    <transition
      name="custom-classes-transition"
      enter-active-class="animated fadeInUp delay-animation-1000"
      leave-active-class="animated fadeOutDown"
    >
      <TheCookiebar v-if="showCookiebar" @hide="handleHideCookiebar" />
    </transition>
  </div>
</template>

<script>
import scrollMonitor from 'scrollmonitor'
import { debounce } from 'lodash'
import { addListeners } from '@/helpers/event'
import { mapState, mapMutations, mapActions } from 'vuex'

import BaseSpinner from '@/components/BaseSpinner'
import TheCookiebar from '@/components/TheCookiebar'

export default {
  name: 'App',
  components: {
    BaseSpinner,
    TheCookiebar,
  },
  data() {
    return {
      lastScrollTop: 0,
      showCookiebar: false,
    }
  },
  computed: {
    ...mapState('breakpoints', ['device', 'breakpoint']),
    ...mapState('api', ['content']),
    successStatus() {
      return this.content.status.success
    },
    failStatus() {
      return this.content.status.fail
    },
  },
  watch: {
    failStatus() {
      if (this.failStatus) {
        this.$router.replace({ name: 'error' })
      }
    },
  },
  created() {
    addListeners(window, 'resize', this.handleWindowResize)
    addListeners(window, 'scroll', this.handleWindowScroll)
    this.fetchContent().then(() => {
      this.showCookiebar = true
    })
  },
  mounted() {
    this.setBreakpoints()
    this.onWindowResize()
    this.onWindowScroll()
  },
  methods: {
    ...mapMutations('breakpoints', ['updateBreakpoints']),
    ...mapActions('breakpoints', ['updateWindowWidth', 'updateWindowHeight']),
    ...mapMutations('page', ['setScrollPosition', 'setScrollDirection']),
    ...mapActions('api', ['fetchContent']),
    handleWindowScroll: debounce(function(event) {
      this.onWindowScroll()
    }),
    handleWindowResize: debounce(function() {
      this.onWindowResize()
    }, 100),
    handleHideCookiebar() {
      this.showCookiebar = false
    },
    onWindowResize() {
      this.updateWindowWidth(window.innerWidth)
      this.updateWindowHeight(window.innerHeight)
      this.updateScrollMonitor()
    },
    onWindowScroll() {
      // const scrollpos = window.scrollY || window.scrollTop || document.getElementsByTagName('html')[0].scrollTop
      // const st = window.pageYOffset || document.documentElement.scrollTop
      const st =
        window.scrollY ||
        window.scrollTop ||
        document.getElementsByTagName('html')[0].scrollTop

      this.setScrollDirection(st > this.lastScrollTop ? 'down' : 'up')
      this.lastScrollTop = st <= 0 ? 0 : st
      this.setScrollPosition(this.lastScrollTop)
    },
    setBreakpoints() {
      this.updateBreakpoints([
        { name: 'small', min: 0, max: 719 },
        { name: 'medium', min: 720, max: 1079 },
        { name: 'large', min: 1080, max: 1439 },
        { name: 'xlarge', min: 1440, max: 20000 },
      ])
      this.updateWindowWidth(window.innerWidth)
      this.updateWindowHeight(window.innerHeight)
    },
    updateScrollMonitor() {
      scrollMonitor.recalculateLocations()
      scrollMonitor.update()
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/styles/base.scss';

.App {
  padding-bottom: 0 !important;
  max-width: $page-max;
  margin: 0 auto;
  overflow-x: hidden;

  // Layout

  &__Layout {
    position: relative;
    width: 100%;
  }

  &__Spinner-Wrapper {
    width: 100vw;
    height: 100vh;

    .BaseSpinner {
      @include center-abs;
    }
  }
}
</style>
