import { Api } from '@/api'
import StoreFetch from '../_helpers/StoreFetch'

const state = {
  content: StoreFetch.state(),
  newsletter: StoreFetch.state(),
  contact: StoreFetch.state(),
}

const getters = {
  getContent: (state) => {
    return state.content.data
  },
  getContentMetaInfo: (state) => {
    return state.content.data
      ? state.content.data.metaInfo
      : { title: 'La cave de Jonas', meta: {} }
  },
}

const mutations = {
  SET_CONTENT: (state, payload) => {
    state.content = StoreFetch.updateState(state.content, payload)
  },
  SET_NEWSLETTER: (state, payload) => {
    state.newsletter = StoreFetch.updateState(state.newsletter, payload)
  },
  SET_CONTACT: (state, payload) => {
    state.contact = StoreFetch.updateState(state.contact, payload)
  },
}

const actions = {
  fetchContent({ commit }) {
    commit('SET_CONTENT')
    return new Promise((resolve, reject) => {
      return Api.fetch('content')
        .get()
        .then((response) => {
          commit('SET_CONTENT', response.data)
          resolve()
        })
        .catch((e) => {
          commit('SET_CONTENT', e)
          reject(e)
        })
    })
  },
  postNewsletter({ commit }, params) {
    commit('SET_NEWSLETTER')
    return new Promise((resolve, reject) => {
      return Api.fetch('newsletter')
        .post(params)
        .then(() => {
          commit('SET_NEWSLETTER', {})
          resolve()
        })
        .catch((e) => {
          commit('SET_NEWSLETTER', e)
          reject(e)
        })
    })
  },
  postContact({ commit }, params) {
    console.log('postContact', params)
    commit('SET_CONTACT')
    return new Promise((resolve, reject) => {
      return Api.fetch('contact')
        .post(params)
        .then(() => {
          commit('SET_CONTACT', {})
          resolve()
        })
        .catch((e) => {
          commit('SET_CONTACT', e)
          reject(e)
        })
    })
  },
}

export default {
  state,
  getters,
  mutations,
  actions,
  namespaced: true,
}
